import React, { Fragment } from 'react'
//import './App.css'

import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'

import { Input, Button, Form, Icon, message } from 'antd'
import { Row, Col, PageHeader, Alert, Spin } from 'antd'
//import 'antd/dist/antd.css'

import { __log } from '../utils/log.js'

////////////////////////////////////////////////////
//
// NOTE: The default export is wrapped in a HOC
//
////////////////////////////////////////////////////

const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`

class HorizontalLoginForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      authenticationAttempts: 0,
      authenticated: false,
      expectingResponse: false
    }
  }

  componentDidMount() {
    // To disabled submit button at the beginning.
    __log(`child componentDidMount()`)
    // this.props.form.validateFields()
  }

  componentWillUnmount(){
    __log(`child will unmount`)
  }

  handleOnSubmit = e => {
    e.preventDefault()
    __log(`onSubmit: authenticationAttempts: ${this.state.authenticationAttempts}, authenticated: ${this.state.authenticated}`)
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({expectingResponse: true})
        // anything else needed to be done before firing GraphQL query
      }
    })
  } // handleOnSubmit

  _hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }

  _postLoginSubmitError = (error) => {
    // example error: postgres not whitelist for an IP.
    // @NA TODO: don't show this error message to front-end. Sensitive data in error output.
    // Ideally this goes to something like Sentry.
    //
    // @NA TODO: handle error more gracefully when user hits submit with nothing entered
    // for username + password. Currently a slew of React errors barf out
    this.setState( (prevState, props) => ({
      expectingResponse: false
      // Don't increment authenticationAttempts b/c this doesn't count
      // as a successful attempt from the user (e.g. if it's server side that failed).
      // Also, if authenticationAttempts is incremented, then the React form
      // error message may be incorrectly displayed (i.e. try user/pass again)
    }))
    if(error){
      let descriptiveError = false
      if(error.graphQLErrors){
        error.graphQLErrors.forEach( (elem) => {
          message.error(elem.message)
          descriptiveError = true
        })
      }
      if(error.networkError){
        message.error(error.networkError)
        descriptiveError = true
      }
      if(!descriptiveError) {
        message.error(error.toString())
      }
    }else{
      message.error('Whoops! Something broke temporarily on our side of things (not your fault). Please email or call us.')
    }
  }

  _postLoginSubmit = async (data) => { // handle response from GraphQL query
    __log(`_postLoginSubmit() data.login: `, data.login)
    this.setState( (prevState, props) => ({
      expectingResponse: false,
      authenticationAttempts: prevState.authenticationAttempts + 1
    }))
    if(data && data.login != null){ // if authenticated
      this.setState({ authenticated: true },
        () => {
          message.success(`Success! You are logged in.`)
          localStorage.setItem('LocalStorageAuthTokenKeyName', data.login)
          __log(`_postLoginSubmit() have set authenticated to true`)
          this.props.isLoggedInCheck()
        })
    }else{ // if auth fails
      this.setState({ authenticated: false },
        () => {
          localStorage.removeItem('LocalStorageAuthTokenKeyName')
          __log(`_postLoginSubmit() have set authenticated to false`)
          this.props.isLoggedInCheck()
        })
    }
  } // _postLoginSubmit

  render() {
    const { getFieldValue, getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form

    // Only show error after a field is touched
    const usernameError = isFieldTouched('username') && getFieldError('username')
    const passwordError = isFieldTouched('password') && getFieldError('password')

    __log(`child render() authenticationAttempts ${this.state.authenticationAttempts}`)
    __log(`child render() authenticated ${this.state.authenticated}`)

    return (
      <Fragment>
        <Row>
          <Col span={24}>
            <PageHeader title='Welcome back' subTitle='Please sign in' />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form layout="inline" onSubmit={this.handleOnSubmit}>
              <Form.Item validateStatus={usernameError ? 'error' : ''}
              debuggerhelp={usernameError || ''}>
                {
                  getFieldDecorator(
                    'username',
                    {
                      rules: [{ required: true, min: 1, max: 255, whitespace: true, message: 'Missing username' }],
                    }
                  )(
                    <Input
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Username"
                    />,
                  )
                }
              </Form.Item>
              <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                {getFieldDecorator('password', {
                  rules: [{ required: true, min: 1, max: 255, whitespace: true, message: 'Missing password' }],
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Password"
                  />,
                )}
              </Form.Item>
              <Form.Item>
                <Mutation
                  mutation={LOGIN_MUTATION}
                  variables={{ email: getFieldValue('username'), password: getFieldValue('password') }}
                  onCompleted={data => this._postLoginSubmit(data)}
                  onError={ error => this._postLoginSubmitError(error) }
                >
                {
                  (mutation) => {
                    return (
                      <Button
                        type='primary'
                        htmlType='submit'
                        className="pointer mr2 button"
                        onClick={mutation} disabled={this._hasErrors(getFieldsError())}
                      >
                        Login
                      </Button>
                    )
                  }
                }
                </Mutation>
                &nbsp; &nbsp;
                { this.state.expectingResponse ? <Spin /> : null}
          { this.state.authenticationAttempts > 0 && this.state.authenticated === false ? <Alert message="      Email or password incorrect. Try again?" type="error" /> : null}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Fragment>
    )
  }
}

export default Form.create({name: 'horizontal_login'})(HorizontalLoginForm)
