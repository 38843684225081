import React, { Fragment, Component } from 'react'
import ReactMarkdown from 'react-markdown'
import { Redirect } from 'react-router-dom'
import gql from 'graphql-tag'
import { Query, Mutation } from 'react-apollo'
import { Row, Col, Card, message, Typography, Button, PageHeader, Spin } from 'antd'
import { Link } from 'react-router-dom'
import { isLoggedInCheck } from '../utils/isLoggedInCheck.js'

const { Paragraph } = Typography

const GET_THOUGHT_BY_ID = gql`
  query GetThoughtById($thoughtId: Int!){
    getThoughtById(thoughtId: $thoughtId){
      text
    }
  }
`

const GET_MENTAL_MODELS_BY_THOUGHT = gql`
  query GetMentalModelsByThought($thoughtId: Int!){
    getMentalModelsByThought(thoughtId: $thoughtId){
      id
      text
    }
  }
`

const UPDATE_THOUGHT_MUTATION = gql`
  mutation UpdateThought($thoughtId: Int!, $thoughtText: String!){
    updateThought(thoughtId: $thoughtId, thoughtText: $thoughtText){
      text
    }
  }
`

class ThoughtDetailComponent extends Component {
    // @NA TODO: performance improvement: make this 1 GraphQL query
    // instead of 2
    constructor(props){
      super(props)
      this.state = {
        parsedThoughtId: Number.parseInt(this.props.match.params.thoughtId),
        thoughtText: '',
        previousThoughtText: '',
        isLoggedIn: !this.props.initialLoginCheckPending ? this.props.isLoggedInCheck : false,
        loginCheckPending: true
      }
    }

    componentDidMount(){
      isLoggedInCheck({verifyTokenFromServer: false})
      .then((results) => { this.setState({isLoggedIn: results, loginCheckPending: false})})
      document.title = 'TID: ' + this.state.parsedThoughtId.toString()
    }

    _onCompletedGetThought = (data) => {
      if(data.getThoughtById && data.getThoughtById.text){
        this.setState({thoughtText: data.getThoughtById.text})
        document.title = 'TID: ' + this.state.parsedThoughtId.toString() + ': ' + data.getThoughtById.text
      }
    }

    _onCompletedUpdateThought = (data) => {
      message.success(`Successfully updated thought`)
    }

    _onErrorUpdateThought = (data) => {
      // restore to UI the previous thought text if update failed
      message.error(`Whoops, something broke on our end. Unable to update thought ID: ${this.state.parsedThoughtId}`)
      this.setState( (prevState) => ({
        thoughtText: prevState.previousThoughtText
      }))
    }

    _handleOnChangeThoughtText = (newThoughtText, mutation) => {
      this.setState( (prevState) => ({
        previousThoughtText: prevState.thoughtText,
        thoughtText: newThoughtText
      }))
      mutation({
        variables: {
          thoughtId: this.state.parsedThoughtId,
          thoughtText: newThoughtText
        }
      })
    }

     _onCompletedGetMentalModelsByThought = (data) => {
      if(data.getMentalModelsByThought.length === 0){
        message.error('Sorry, no such thought. Would you like to add it?')
      }
    }

    render(){
      const regex=/^([0-9]+)$/
      if(!this.state.parsedThoughtId || this.props.match.params.thoughtId.search(regex) === -1){
        return <Redirect to='/' />
      }

      if(this.state.loginCheckPending === false && this.state.isLoggedIn === false){
        return <Redirect to='/login' />
      }

      return (
          <Fragment>
              <Row type="flex" justify="center">
                <Col span={24}>
                  {
                    this.state.loginCheckPending ? <div><Spin /> login check</div> : null
                  }
                </Col>
              </Row>
              <Row type="flex" justify="center">
                <Col xs={24} md={20} lg={12}>
                  <PageHeader title='Thought' subTitle='and associated mental models' />
                </Col>
              </Row>
              <Row type="flex" justify="center">
                <Col xs={24} md={20} lg={12}>
                  <Query
                    query={GET_THOUGHT_BY_ID}
                    variables={{ thoughtId: this.state.parsedThoughtId}}
                    onCompleted={ data => this._onCompletedGetThought(data)}
                  >
                  {
                    // @NA TODO: needs to be factorered out
                    ({ data, loading, error }) => {
                      if (loading) {
                        return <div><Spin /></div>
                      }
                      if (error || data === null || data.getThoughtById === null) {
                        return <div>Err</div>
                      }
                      return (
                          <Card title="Thought">
                            <Mutation
                              mutation={UPDATE_THOUGHT_MUTATION}
                              onCompleted={ data => this._onCompletedUpdateThought(data)}
                              onError={ data => this._onErrorUpdateThought(data)}
                              >
                            {
                              (mutation, {loading, error, data}) => {
                                return (
                                    <Paragraph editable={{ onChange: e => this._handleOnChangeThoughtText(e, mutation) }}>
                                      { this.state.thoughtText }
                                    </Paragraph>
                                  )
                              }
                            }
                            </Mutation>
                          </Card>
                        )
                    }
                  }
                  </Query>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                <h1>&nbsp;</h1>
                </Col>
              </Row>
              <Query
                query={GET_MENTAL_MODELS_BY_THOUGHT}
                variables={{ thoughtId: this.state.parsedThoughtId}}
                onCompleted={ data => this._onCompletedGetMentalModelsByThought(data) }
              >
              {
                // @NA TODO: needs to be factorered out
                ({ data, loading, error }) => {
                  if (loading) {
                    return <div><Spin /></div>
                  }
                  if (error || data === null || data.getMentalModelsByThought === null) {
                    return <div>Err</div>
                  }
                  if (data.getMentalModelsByThought.length === 0 ) {
                    return <Redirect to="/" />
                  }
                  return (
                    <Fragment>
                    {
                      data.getMentalModelsByThought.map( (elem) => {
                        return (
                            <Fragment key={elem.id}>
                              <Row type="flex" justify="center">
                                <Col xs={24} md={20} lg={12}>
                                  <Card title={`Mental Model #${elem.id} `}>
                                    <ReactMarkdown source={ elem.text } />
                                    <Link to={`/mentalmodel/${elem.id}`}>
                                      <Button>Options</Button>
                                    </Link>
                                  </Card>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <h6>&nbsp;</h6>
                                </Col>
                              </Row>
                            </Fragment>
                          )
                      })
                    }
                    </Fragment>
                    )
                }
              }
              </Query>
          </Fragment>
        ) // return
    } // render()
}// class

export default ThoughtDetailComponent
