import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { List, Row, Col, Button } from 'antd'

const GET_THOUGHTS = gql`
  query {
    getThoughts {
      id
      text
    }
  }
`

const HomeComponent = (props) => {
  if(props.isLoggedIn === false){
    return(
      <Fragment>
          <Row>
            <Col span={24}>
              You're not logged in. Would you like to do that first?
            </Col>
          </Row>
      </Fragment>
      )
  }

  return (
      <Fragment>
          <Row type="flex" justify="center">
            <Col xs={24} md={16}>
              <Query query={GET_THOUGHTS}>
                {({ data, loading, error }) => {
                  if (loading) {
                    return <div>Loading ...</div>
                  }
                  if (error || data === null || data.getThoughts === null) {
                    return <div>error</div>
                  }
                  return (
                    <List itemLayout="horizontal" header={<h1>Thoughts</h1>}>
                      {
                        data.getThoughts.map((thought) => {
                          return (
                             <List.Item key={thought.id}>
                              <ReactMarkdown source={ thought.text } /> &nbsp;
                              <Button>
                                <Link to={
                                            {
                                              pathname: `/thought/${thought.id}`,
                                              state: {
                                                sentFromMainPage: true
                                              }
                                            }
                                          }>
                                  More
                                </Link>
                              </Button>
                             </List.Item>
                            )
                        })
                      }
                    </List>
                  )
                }}
              </Query>
            </Col>
          </Row>

      </Fragment>
    )
}
export default HomeComponent
