import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import { Form, Input, Button, message } from 'antd'

import { __log } from '../utils/log.js'

const { TextArea } = Input

const ADD_MENTAL_MODEL_MUTATION = gql`
  mutation AddMentalModel($mentalModelText: String!, $thoughts: [String!], $questions: [String!]){
    addMentalModel(text: $mentalModelText, thoughts: $thoughts, questions: $questions) {
      text
      id
    }
  }
`

class MentalModelForm extends React.Component {
  handleOnSubmit = (e, mutation) => {
    e.preventDefault()
    let thoughts = []
    let questions = []
    const thought = this.props.form.getFieldValue('thought')
    if(thought && thought.length > 0){
      thoughts = [thought.trim()]
    }
    const question = this.props.form.getFieldValue('question')
    if(question && question.length > 0){
      questions = [question.trim()]
    }
    this.props.form.validateFields( (err, values) => {
      if (!err){ // no errors!
        __log(`handleOnSubmit: values: `, values)
      }else{ // handle form errors
        __log(`handleOnSubmit: err: `, err)
      }
    })
    mutation({
      variables: {
                  mentalModelText: this.props.form.getFieldValue('mentalModel'),
                  thoughts: thoughts,
                  questions: questions
      }
    })
  } // handleOnSubmit

  _hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }

  _afterSubmit = (data) => {
    if(data.addMentalModel.__typename === 'MentalModel'){
      // if successfully added on backend
      message.success(`Successfully added mental model: ${data.addMentalModel.text.substring(0, 10)}...`)
      this.props.form.resetFields()
    }
  } // _afterSubmit

  _onError = (data) => {
    if(data){
      let descriptiveError = false
      if(data.graphQLErrors){
        data.graphQLErrors.forEach( (elem) => {
          message.error(elem.message)
          descriptiveError = true
        })
      }
      if(data.networkError){
        message.error(data.networkError)
        descriptiveError = true
      }
      if(!descriptiveError) {
        message.error(data.toString())
      }
    }else{
      message.error('Whoops! Something broke temporarily on our side of things (not your fault). Please email or call us.')
    }
    // this.props.form.setFields({
    //   ['thought']: { errors: ['some err here']}
    // })
  } // _onError

  render(){
    const { getFieldDecorator, getFieldsError } = this.props.form
    // const mentalModelError = isFieldTouched('mentalModel') && getFieldError('mentalModel')
    return(
            <Mutation
              mutation={ADD_MENTAL_MODEL_MUTATION}
              onCompleted={ data => this._afterSubmit(data)}
              onError={ data => this._onError(data)}
            >
            {
              (mutation, {loading, error, data}) => {
                if (error) {
                  __log('error.graphQLErrors: ', error.graphQLErrors)
                  __log('error.networkError: ', error.networkError)
                }
                return(
                  <Form onSubmit={e => this.handleOnSubmit(e, mutation)}>
                    <Form.Item label='Mental Model'>
                    {
                      getFieldDecorator(
                        'mentalModel',
                        {
                          rules: [{required: true, min: 1, whitespace: false, message: 'Missing or too short of a mental model'}]
                        }
                        )(<TextArea />)
                    }
                    </Form.Item>
                    <Form.Item label='Thought'>
                    {
                      getFieldDecorator(
                        'thought',
                        {
                          rules: [{required: false, min: 1, message: 'Too short of a thought?'}]
                        }
                        )(<Input />)
                    }
                    </Form.Item>
                    <Form.Item label='Question'>
                    {
                      getFieldDecorator(
                        'question',
                        {
                          rules: [{required: false, min: 1, message: 'Too short of a question?'}]
                        }
                        )(<Input />)
                    }
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type='primary'
                        htmlType='submit'
                        disabled={this._hasErrors(getFieldsError())}
                      >
                        Add new mental model {loading && <span>(loading...)</span>}
                      </Button>
                    </Form.Item>
                  </Form>
                  )
              }
            }
            </Mutation>
      )
  } // render
} // class

export default Form.create({name: 'mentalmodel_hoc'})(MentalModelForm)
