import React, { Fragment, Component } from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import ReactMarkdown from 'react-markdown'
import { Button, Row, Col, message, PageHeader, Spin } from 'antd'
import { Redirect } from 'react-router-dom'
import MentalModelTextForm from './MentalModelTextForm.js'
import { isLoggedInCheck } from '../utils/isLoggedInCheck.js'

const GET_MENTAL_MODEL_BY_ID = gql`
  query GetMentalModelById($mentalModelId: Int!){
    getMentalModelById(mentalModelId: $mentalModelId){
      text
    }
  }
`

class MentalModelDetailComponent extends Component {
  constructor(props){
    super(props)
    this.state = {
      mentalModelId: Number.parseInt(this.props.match.params.mentalModelId),
      mentalModelText: '',
      editModeOn: false,
      isLoggedIn: !this.props.initialLoginCheckPending ? this.props.isLoggedInCheck : false,
      loginCheckPending: true
    }
    this._setEditMode = this._setEditMode.bind(this)
    this._updateMentalModelTextAfterSubmit = this._updateMentalModelTextAfterSubmit.bind(this)
  }

  componentDidMount(){
      isLoggedInCheck({verifyTokenFromServer: false})
      .then((results) => { this.setState({isLoggedIn: results, loginCheckPending: false})})
  }

  _onCompletedGetMentalModel = (data) => {
    if(data.getMentalModelById === null){
      message.error('Sorry, no such mental model. Would you like to add it?')
    }else{
      this.setState({mentalModelText: data.getMentalModelById.text})
    }
  }

  _handleOnClickEdit = (e) => {
    this.setState({editModeOn: true})
  }

  _setEditMode = (trueorfalse) => {
    this.setState({editModeOn: trueorfalse})
  }

  _updateMentalModelTextAfterSubmit = (newText) => {
    this.setState({mentalModelText: newText})
  }

  render(){
    const re=/^([0-9]+)$/ // regex to make sure ID is valid int only
    if(!this.state.mentalModelId || this.props.match.params.mentalModelId.search(re) === -1){
      return <Redirect to='/' />
    }

    if(this.state.loginCheckPending === false && this.state.isLoggedIn === false){
      return <Redirect to='/login' />
    }

    return(
        <Fragment>
          <Row type="flex" justify="center">
            <Col xs={24} md={20} lg={12}>
              {
                this.state.loginCheckPending ? <div><Spin /> login check</div> : null
              }
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} md={20} lg={12}>
              <PageHeader title='Mental Model' subTitle={ this.state.editModeOn ? 'edit' : null } />
            </Col>
          </Row>
          <Query
            query={GET_MENTAL_MODEL_BY_ID}
            variables={{ mentalModelId: this.state.mentalModelId }}
            onCompleted={ data => this._onCompletedGetMentalModel(data) }
            >
            {
              ({data, loading, error}) => {
                if(loading){
                  return <div><Spin /></div>
                }
                if(error || data === null){
                  // likely something broke on backend
                  return <div>Err</div>
                }
                if(data.getMentalModelById === null){
                  // likely tried to lookup an invalid / non-existent mm
                  return <Redirect to='/' />
                }
                return(
                    <Row type="flex" justify="center">
                      <Col xs={24} md={20} lg={12}>
                        {
                          this.state.editModeOn ?
                              <MentalModelTextForm
                                mentalModelId={this.state.mentalModelId}
                                mentalModelText={this.state.mentalModelText}
                                updateMentalModelTextAfterSubmit = {this._updateMentalModelTextAfterSubmit}
                                setEditMode={this._setEditMode} />
                              :
                              <Fragment>
                                <ReactMarkdown source={this.state.mentalModelText} />
                                <Button onClick={ e => this._handleOnClickEdit(e) }>edit</Button>
                              </Fragment>
                        }
                      </Col>
                    </Row>
                  ) // return
              }
            }
          </Query>
        </Fragment>
      ) // return
  } // render
} // class

export default MentalModelDetailComponent
