import React, { Fragment, Component } from 'react'
import { Icon, Select, Tooltip, Switch, Divider, Row, Col, Form, Input } from 'antd'
import { Button } from 'antd'
import { __log } from '../utils/log.js'
import { client } from '../index.js' // ApolloClient
import gql from 'graphql-tag'

const { Option } = Select

const formItemLayout = {
  labelCol: {
    span: 8, offset: 0
    // xs: { span: 8 },
    // sm: { span: 8 },
  },
  wrapperCol: {
    span: 8, offset: 0
    // xs: { span: 16 },
    // sm: { span: 16 },
  },
}

const SET_SIGNUP_PERSONAL_INFO = gql`
  mutation SetSignupPersonalInfo($signupFirstName: String!,
                                 $signupMiddleName: String,
                                 $signupLastName: String!,
                                 $signupTitle: String!,
                                 $signupEmail: String!,
                                 $signupPhone1: String!,
                                 $signupPhone1Sms: Boolean,
                                 $signupPassword: String!,
                                 ){

    setSignupPersonalInfo(signupFirstName: $signupFirstName,
                          signupMiddleName: $signupMiddleName,
                          signupLastName: $signupLastName,
                          signupTitle: $signupTitle,
                          signupEmail: $signupEmail,
                          signupPhone1: $signupPhone1,
                          signupPhone1Sms: $signupPhone1Sms,
                          signupPassword: $signupPassword
                          ) @client
  }
`

class SignupBasicForm extends Component {
  _hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }

  _handleOnSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields( (err, values) => {
      if (!err){ // no errors!
        const vars = {
          signupTitle: values.title,
          signupFirstName: values.first_name,
          signupMiddleName: values.middle_name || '',
          signupLastName: values.last_name,
          signupEmail: values.email,
          signupPhone1: values.phone1,
          signupPhone1Sms: values.phone1_sms === undefined
                           ? true // default to true
                           : values.phone1_sms,
          signupPassword: values.password
        }
        client.mutate({
           mutation: SET_SIGNUP_PERSONAL_INFO,
           variables: vars
         })
        this.props.changeToStep(1)
      }else{ // handle form errors
        __log(`handleOnSubmit: err: `, err)
      }
    })
  }

   _onChangeTitle(value) {
    //console.log(`selected title ${value}`)
  }

  titles = [
    {id: 'dr', stringName: 'Dr.'},
    {id: 'mr', stringName: 'Mr.'},
    {id: 'mrs', stringName: 'Mrs.'},
    {id: 'ms', stringName: 'Ms.'},
    {id: 'prof', stringName: 'Prof.'},
    {id: 'rev', stringName: 'Rev.'},
    {id: 'rabbi', stringName: 'Rabbi'}
    // {id: 'sister', stringName: 'Sister'}
  ]

  render(){
    const { getFieldDecorator, getFieldsError } = this.props.form
    return(
      <Fragment>
        <Form {...formItemLayout} onSubmit={ e => this._handleOnSubmit(e)}>
          <Row>
            <Col span={16} offset={4}>
              <Divider orientation='left'>
                Your Name
              </Divider>
              <Form.Item label='Title'>
              {
                getFieldDecorator(
                  'title',
                  {
                    rules: [{
                        required: true,
                        message: 'Please select'
                      }]
                  }
                  )(
                    <Select style={{ width: 90 }} onChange={this._onChangeTitle}>
                    {
                      this.titles.map( item => (<Option key={item.id} value={item.id}>{item.stringName}</Option>))
                    }
                    </Select>
                  )
              }
              </Form.Item>
              <Form.Item label='First Name'>
              {
                getFieldDecorator(
                  'first_name',
                  {
                    rules: [{
                        required: true,
                        min: 2,
                        whitespace: false,
                        message: 'Missing or too short'
                      }]
                  }
                  )(<Input />)
              }
              </Form.Item>
              <Form.Item label='Middle Name'>
              {
                getFieldDecorator('middle_name', {
                  rules: [{
                    required: false,
                    min: 1,
                    whitespace: false,
                    message: 'Error'
                  }]
                })(<Input />)
              }
              </Form.Item>
              <Form.Item label='Last Name'>
              {
                getFieldDecorator('last_name', {
                  rules: [{
                    required: true,
                    min: 2,
                    whitespace: false,
                    message: 'Missing or too short'
                  }]
                })(<Input />)
              }
              </Form.Item>
              <Form.Item label='Suffix(es) after your name'>
              {
                getFieldDecorator(
                  'name_suffix',
                  {
                    rules: [{
                        required: false,
                        whitespace: false,
                      }]
                  }
                  )(<Input />)
              }
                <small><span>e.g. Ph.D, MFT, LCSW (if applicable)</span></small>
              </Form.Item>

              <Divider orientation='left'>Contact</Divider>

              <Form.Item label='Email'>
              {
                getFieldDecorator('email', {
                  rules: [
                  {
                    type: 'email',
                    message: 'Is there a typo in your email?'
                  },
                  {
                    required: true,
                    whitespace: false,
                    message: 'Please enter your email'
                  },

                  ]
                })(<Input />)
              }
                <Tooltip title="We promise!">
                  <small><span>100% <strong>NO SPAM</strong> tolerance policy</span></small>
                </Tooltip>
              </Form.Item>
              <Form.Item label='Phone Number'>
              {
                getFieldDecorator('phone1', {
                  rules: [
                  {
                    required: true,
                    whitespace: false,
                    message: 'Please enter your phone number'
                  },
                  {
                    min: 10,
                    message: 'Phone number is too short'
                  },
                  ]
                })(<Input />)
              }
              </Form.Item>
              <Form.Item label='Receive Text Messages?'>
              {
                getFieldDecorator('phone1_sms', {
                  // rules: [{
                  //   // required: true,
                  //   // message: 'Please select one'
                  // }]
                })(<Switch defaultChecked checkedChildren="Yes" unCheckedChildren="No" />)
              }
                <Tooltip title="Time-sensitive!">
                  <small><span>&nbsp; Urgent only (e.g. new client inquiry)</span></small>
                </Tooltip>
              </Form.Item>

              <Divider orientation='left'>
                Login
              </Divider>

              <Form.Item label='Username'>
              {
                // @NA TODO: need to blacklist reserved keywords here
                getFieldDecorator('username', {
                  rules: [
                  {
                    required: true,
                    min: 1,
                    whitespace: false,
                    message: 'Username is required'
                  }]
                })(
                  <Input />
                )
              }
              <small>Username will be part of your profile's URL link</small>
              </Form.Item>

              <Form.Item label='Password'>
              {
                getFieldDecorator('password', {
                  rules: [
                  {
                    required: true,
                    message: 'Password is required'
                  },
                  {
                    min: 8,
                    whitespace: false,
                    message: 'At least 8 characters long'
                  }
                  ]
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                  />
                )
              }
              </Form.Item>

            </Col>
          </Row>
          <Row>
            <Col offset={4} span={16}>
              <Form.Item>
                <Button type='primary'
                  htmlType='submit'
                  disabled={this._hasErrors(getFieldsError())}
                >
                  Next
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Fragment>
      ) // return
  } // render
} // class

export default Form.create({name: 'signupbasicform_hoc'})(SignupBasicForm)
