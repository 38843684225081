import React, { Fragment } from 'react'
import { isLoggedInCheck } from '../utils/isLoggedInCheck.js'
import { Redirect } from 'react-router-dom'
import { Row, Col, PageHeader, Spin, Steps } from 'antd'
import gql from 'graphql-tag'

import SignupBasicForm from './SignupBasicForm.js'
import SignupPracticeForm from './SignupPracticeForm.js'
import { client } from '../index.js' // ApolloClient

const { Step } = Steps

/*
// this is for testing apollo local client
import { Query } from 'react-apollo'
const GET_SIGNUP_PERSONAL_INFO = gql`
{
  signupFirstName @client {
    value
  }
  signupMiddleName @client {
    value
  }
  signupLastName @client {
    value
  }
  signupTitle @client {
    value
  }
  signupEmail @client {
    value
  }
  signupPhone1 @client {
    value
  }
  signupPhone1Sms @client {
    value
  }
  signupPassword @client {
    value
  }
}
`
*/

const stepsContent = [
  {
    title: 'Contact Info ',
    description: 'Basic personal information'
  },
  {
    title: 'Practice Info ',
    description: 'Practice, license, credentials'
  },
  {
    title: 'Done',
    description: 'Yay!'
  },
]

class SignupComponent extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isLoggedIn: false,
      loginCheckPending: true,
      currentStep: 0 // @NA TODO temp for dev. reset to 0 when done
    }
  }

  componentDidMount(){
    isLoggedInCheck({verifyTokenFromServer: false})
    .then((results) => { this.setState({isLoggedIn: results, loginCheckPending: false})})
  }

  _changeToStep = (newStepNum) => {
    // this is for sub components to be able to navigate the
    // parent forwards or backwards in steps
    this.setState({currentStep: newStepNum})
  }

  _onClickNextStep() {
    const currentStep = this.state.currentStep + 1
    this.setState({ currentStep: currentStep })
  }

  _onClickPrevStep() {
    const currentStep = this.state.currentStep - 1
    this.setState({ currentStep: currentStep })
  }

  _onClickTest(){
    client.mutate({
      mutation: gql`
        mutation SetSignupPersonalInfo($signupFirstName: String!){
          setSignupPersonalInfo(signupFirstName: $signupFirstName) @client {
            signupFirstName
          }
        }
      `,
      variables: { signupFirstName: 'new signup first name hey joe!'}
    })
  }

  render(){
    if(this.state.loginCheckPending === false && this.state.isLoggedIn === true){
      return <Redirect to='/' />
    }
    return(
        <Fragment>
          <Row>
            <Col span={24}>
              {
                this.state.loginCheckPending ? <div><Spin /></div> : null
              }
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <PageHeader title='FREE Signup' subTitle='Build up your caseload' />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <h1>&nbsp;</h1>
            </Col>
          </Row>
          <Row>
            <Col offset={4} span={16}>
              <Steps current={this.state.currentStep}>
              {
                stepsContent.map( (item, idx) => (<Step key={idx} title={item.title} description={item.description}/>))
              }
              </Steps>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <h1>&nbsp;</h1>
              <h1>&nbsp;</h1>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              { this.state.currentStep === 0 && <SignupBasicForm changeToStep={this._changeToStep} /> }
              { this.state.currentStep === 1 && <SignupPracticeForm changeToStep={this._changeToStep} /> }
            </Col>
          </Row>

{/*          <Row>
            <Col span={24}>
              <Query query={ GET_SIGNUP_PERSONAL_INFO }>
              {
                ({ data }) => {
                  if(data){
                    return (
                        <div>
                          signupFirstName: {data.signupFirstName.value} <br />
                          signupMiddleName: {data.signupMiddleName.value} <br />
                          signupLastName: {data.signupLastName.value} <br />
                          signupTitle: {data.signupTitle.value} <br />
                          signupEmail: {data.signupEmail.value} <br />
                          signupPhone1: {data.signupPhone1.value} <br />
                          signupPhone1Sms: {data.signupPhone1Sms.value.toString()} <br />
                          signupPassword: {data.signupPassword.value} <br />
                        </div>
                      )
                  }else{
                    return null
                  }
                }
              }
              </Query>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button onClick={ this._onClickTest }>
              test local apollo state write
              </Button>
            </Col>
          </Row>
*/}
          <Row>
            <Col span={24}>
              <h1>&nbsp;</h1>
            </Col>
          </Row>
        </Fragment>
      ) // return
  } // render
} // class

export default SignupComponent
