import React, { Fragment, Component } from 'react'
import { Form, Input } from 'antd'
import gql from 'graphql-tag'
import { Button, message } from 'antd'
import { Mutation } from 'react-apollo'
import { __log } from '../utils/log.js'

const { TextArea } = Input

const UPDATE_MENTAL_MODEL_MUTATION = gql`
  mutation UpdateMentalModel($mentalModelId: Int!, $mentalModelText: String!){
    updateMentalModel(mentalModelId: $mentalModelId, mentalModelText: $mentalModelText){
      id
    }
  }
`

class MentalModelTextForm extends Component {
  constructor(props){
    super(props)
    this.state = {
      mentalModelId: this.props.mentalModelId,
      mentalModelText: this.props.mentalModelText,
      newMentalModelText: ''
    }
  }

  componentDidMount(){
    this.props.form.setFieldsValue({
      mentalModelText: this.props.mentalModelText,
    })
  }

  _hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }

  _onError = (data) => {
    __log(data)
    if(data){
      let descriptiveError = false
      if(data.graphQLErrors){
        data.graphQLErrors.forEach( (elem) => {
          message.error(elem.message)
          descriptiveError = true
        })
      }
      if(data.networkError){
        message.error(data.networkError)
        descriptiveError = true
      }
      if(!descriptiveError) {
        message.error(data.toString())
      }
    }else{
      message.error('Whoops! Something broke temporarily on our side of things (not your fault). Please email or call us.')
    }
  }

  _onCompleted = (data) => {
    // @NA TODO: performance improvement: updateMentalModel doesn't actually need to return a mm
    if(data.updateMentalModel.__typename === 'MentalModel'){
      message.success(`Successfully updated mental model!`)
    }
    this.props.setEditMode(false)
    this.props.updateMentalModelTextAfterSubmit(this.state.newMentalModelText) // update the mm text on parent
  }

  _handleOnSubmit = (e, mutation) => {
    e.preventDefault()
    this.setState({newMentalModelText: this.props.form.getFieldValue('mentalModelText')})
    mutation({
      variables: {
        mentalModelId: this.state.mentalModelId,
        mentalModelText: this.props.form.getFieldValue('mentalModelText')
      }
    })
  }

  render(){
    const { getFieldDecorator, getFieldsError } = this.props.form
    return(
        <Fragment>
          <Mutation
            mutation={UPDATE_MENTAL_MODEL_MUTATION}
            onError={ data => this._onError(data) }
            onCompleted={ data => this._onCompleted(data) }
          >
          {
            (mutation, {loading, data, error}) => {
              if(loading){
                return <div>Loading ...</div>
              }

              if (error) {
                __log('error.graphQLErrors: ', error.graphQLErrors)
                __log('error.networkError: ', error.networkError)
                return <div>Err</div>
              }

              return(
                    <Form onSubmit={ e => this._handleOnSubmit(e, mutation)}>
                      <Form.Item label='Mental Model Text'>
                      {
                        getFieldDecorator('mentalModelText', {
                          rules: [{
                            required: true,
                            min: 1,
                            whitespace: false,
                            message: 'Missing or too short of a mental model'
                          }]
                        })(
                          <TextArea rows={20} autoSize={{ minRows: 10, maxRows: 20 }} />
                          )
                      }
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type='primary'
                          htmlType='submit'
                          disabled={this._hasErrors(getFieldsError())}
                          >
                          Update Mental Model #{this.state.mentalModelId} {loading && <span>(sending...)</span>}
                        </Button>
                      </Form.Item>
                    </Form>
                )
            }
          }
          </Mutation>
        </Fragment>
      )
  }
} // MentalModelTextForm

export default Form.create({name: 'mentalmodeltext_hoc'})(MentalModelTextForm)
