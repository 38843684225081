import React, { Fragment, Component } from 'react'
import { DatePicker, AutoComplete, Divider, Row, Col, Form, Input } from 'antd'
import { Select, Button, Radio, Checkbox } from 'antd'
import { __log } from '../utils/log.js'
import { client } from '../index.js' // ApolloClient
import gql from 'graphql-tag'

/*
// this is just to test that the data did get stored into local apollo cache
import { Query } from 'react-apollo'
const GET_SIGNUP_PRACTICE_INFO = gql`
{
  signupTherapyTypes @client {
    value
  }
  signupExperienceTime @client {
    value
  }
  signupApproaches @client {
    value
  }
}
`
*/

const { Option } = Select
const { MonthPicker } = DatePicker

const formItemLayout = {
  labelCol: {
    span: 12, offset: 0
    // xs: { span: 8 },
    // sm: { span: 8 },
  },
  wrapperCol: {
    span: 12, offset: 0
    // xs: { span: 16 },
    // sm: { span: 16 },
  },
}

const SET_SIGNUP_PRACTICE_INFO = gql`
  mutation SetSignupPracticeInfo($signupAddressLine1: String!,
                                 $signupAddressLine2: String,
                                 $signupApproachOther: String,
                                 $signupApproaches: [String]!,
                                 $signupCity: String!,
                                 $signupExperienceTime: String!,
                                 $signupHoursFri: String,
                                 $signupHoursMon: String,
                                 $signupHoursSat: String,
                                 $signupHoursSun: String,
                                 $signupHoursThu: String,
                                 $signupHoursTue: String,
                                 $signupHoursWed: String,
                                 $signupIssue1: String!,
                                 $signupIssue2: String,
                                 $signupIssue3: String,
                                 $signupIssue4: String,
                                 $signupIssue5: String,
                                 $signupIssue6: String,
                                 $signupIssue7: String,
                                 $signupIssue8: String,
                                 $signupIssue9: String,
                                 $signupIssue10: String,
                                 $signupIssue11: String,
                                 $signupIssue12: String,
                                 $signupIssue13: String,
                                 $signupIssue14: String,
                                 $signupIssue15: String,
                                 $signupIssue16: String,
                                 $signupIssue17: String,
                                 $signupIssue18: String,
                                 $signupIssue19: String,
                                 $signupIssue20: String,
                                 $signupIssueOther: String,
                                 $signupLicenseExpiryDate: String,
                                 $signupLicenseId: String,
                                 $signupLicenseMultiple: String!,
                                 $signupLicenseState: String,
                                 $signupLicenseStatus: String!,
                                 $signupMultipleLocations: String!,
                                 $signupOnlineTherapy: String!,
                                 $signupPracticeName: String,
                                 $signupStateCode: String!,
                                 $signupTherapyTypes: String!,
                                 $signupZip: String!
                                 ){

    setSignupPracticeInfo(signupAddressLine1: $signupAddressLine1,
                          signupAddressLine2: $signupAddressLine2,
                          signupApproachOther: $signupApproachOther,
                          signupApproaches: $signupApproaches,
                          signupCity: $signupCity,
                          signupExperienceTime: $signupExperienceTime,
                          signupHoursFri: $signupHoursFri,
                          signupHoursMon: $signupHoursMon,
                          signupHoursSat: $signupHoursSat,
                          signupHoursSun: $signupHoursSun,
                          signupHoursThu: $signupHoursThu,
                          signupHoursTue: $signupHoursTue,
                          signupHoursWed: $signupHoursWed,
                          signupIssue1: $signupIssue1,
                          signupIssue2: $signupIssue2,
                          signupIssue3: $signupIssue3,
                          signupIssue4: $signupIssue4,
                          signupIssue5: $signupIssue5,
                          signupIssue6: $signupIssue6,
                          signupIssue7: $signupIssue7,
                          signupIssue8: $signupIssue8,
                          signupIssue9: $signupIssue9,
                          signupIssue10: $signupIssue10,
                          signupIssue11: $signupIssue11,
                          signupIssue12: $signupIssue12,
                          signupIssue13: $signupIssue13,
                          signupIssue14: $signupIssue14,
                          signupIssue15: $signupIssue15,
                          signupIssue16: $signupIssue16,
                          signupIssue17: $signupIssue17,
                          signupIssue18: $signupIssue18,
                          signupIssue19: $signupIssue19,
                          signupIssue20: $signupIssue20,
                          signupIssueOther: $signupIssueOther,
                          signupLicenseExpiryDate: $signupLicenseExpiryDate,
                          signupLicenseId: $signupLicenseId,
                          signupLicenseMultiple: $signupLicenseMultiple,
                          signupLicenseState: $signupLicenseState,
                          signupLicenseStatus: $signupLicenseStatus,
                          signupMultipleLocations: $signupMultipleLocations,
                          signupOnlineTherapy: $signupOnlineTherapy,
                          signupPracticeName: $signupPracticeName,
                          signupStateCode: $signupStateCode,
                          signupTherapyTypes: $signupTherapyTypes,
                          signupZip: $signupZip
                          ) @client
  }
`

class SignupPracticeForm extends Component {
  _hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }

  _handleOnSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields( (err, values) => {
      if (!err){ // no errors!
        __log(`handleOnSubmit: values: `, values)
        const vars = {
          signupAddressLine1: values.address_line_1,
          signupAddressLine2: values.address_line_2,
          signupApproachOther: values.approach_other,
          signupApproaches: values.approaches || [],
          signupCity: values.city,
          signupExperienceTime: values.experience_time.toString(),
          signupHoursFri: values.hours_fri,
          signupHoursMon: values.hours_mon,
          signupHoursSat: values.hours_sat,
          signupHoursSun: values.hours_sun,
          signupHoursThu: values.hours_thu,
          signupHoursTue: values.hours_tue,
          signupHoursWed: values.hours_wed,
          signupIssue1: values.issue1,
          signupIssue2: values.issue2,
          signupIssue3: values.issue3,
          signupIssue4: values.issue4,
          signupIssue5: values.issue5,
          signupIssue6: values.issue6,
          signupIssue7: values.issue7,
          signupIssue8: values.issue8,
          signupIssue9: values.issue9,
          signupIssue10: values.issue10,
          signupIssue11: values.issue11,
          signupIssue12: values.issue12,
          signupIssue13: values.issue13,
          signupIssue14: values.issue14,
          signupIssue15: values.issue15,
          signupIssue16: values.issue16,
          signupIssue17: values.issue17,
          signupIssue18: values.issue18,
          signupIssue19: values.issue19,
          signupIssue20: values.issue20,
          signupIssueOther: values.issue_other,
          signupLicenseExpiryDate: values.license_expiry_date,
          signupLicenseId: values.license_id,
          signupLicenseMultiple: values.license_multiple,
          signupLicenseState: values.license_state,
          signupLicenseStatus: values.license_status,
          signupMultipleLocations: values.multiple_locations,
          signupOnlineTherapy: values.online_therapy,
          signupPracticeName: values.practice_name,
          signupStateCode: values.state_code,
          signupTherapyTypes: values.therapyTypes,
          signupZip: values.zip
        }
        client.mutate({
           mutation: SET_SIGNUP_PRACTICE_INFO,
           variables: vars
         })
        //this.props.changeToStep(1)
        console.log('--- execution continues after mutation ---')
      }else{ // handle form errors
        __log(`handleOnSubmit: err: `, err)
      }
    })
  }

  _onChangeExperienceTime = (date, dateString) => {
    // console.log(date, dateString)
  }

  _onChangeApproachesCheckBox = (checkedValues) => {
    // console.log(checkedValues)
  }

  render(){
    const { getFieldDecorator, getFieldsError } = this.props.form
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    }
    const therapyTypes = ['Art Therapist (AT)',
                          'Certified Advanced Alcohol and Drug Counselor (CAADC)',
                          'Certified Alcohol and Drug Counselor (CADC)',
                          'Certified Clinical Mental Health Counselor (CCMHC)',
                          'Certified Clinical Pastoral Therapist (CCPT)',
                          'Certified EMDR Therapist',
                          'Certified Group Psychotherapist (CGP)',
                          'Certified Multiple Addiction Therapist (CMAT)',
                          'Certified Pastoral Counselor (CpastC)',
                          'Certified Sexual Addiction Therapist (CSAT)',
                          'Certified Substance Abuse Counselor (CSAC)',
                          'Counselor',
                          'Credentialed Alcoholism and Substance Abuse Counselor (CASAC)',
                          'Distance Credentialed Counselor',
                          'Education Specialist (EdS)',
                          'Licensed Alcohol and Drug Counselor (LADC)',
                          'Licensed Associate Substance Abuse Counselor (LASAC)',
                          'Licensed Clinical Alcohol and Drug Counselor (LCADC)',
                          'Licensed Clinical Professional Counselor (LCPC)',
                          'Licensed Clinical Social Worker (LCSW)',
                          'Licensed Independent Chemical Dependency Counselor (LICDC)',
                          'Licensed Independent Clinical Social Worker (LICSW)',
                          'Licensed Independent Substance Abuse Counselor (LISAC)',
                          'Licensed Marriage and Family Therapist (LMFT)',
                          'Licensed Master of Social Work (LMSW)',
                          'Licensed Mental Health Counselor (LMHC)',
                          'Licensed Pastoral Counselor (LpastC)',
                          'Licensed Pastoral Psychotherapist (LPP)',
                          'Licensed Professional Clinical Counselor (LPCC)',
                          'Licensed Professional Counselor (LPC)',
                          'Licensed Professional Counselor Intern',
                          'Licensed Psychoanalyst (LP) ',
                          'Licensed Psychological Associate (LPA)',
                          'Licensed Psychologist (LP)',
                          'Limited Licensed Psychologist (LLP)',
                          'Marriage and Family Therapist (MFT)',
                          'Marriage and Family Therapist Associate',
                          'Marriage and Family Therapist Intern',
                          'Master Addiction Counselor (MAC)',
                          'Mental Health Counselor (MHC)',
                          'Military and Family Life Counselor (MFLC)',
                          'National Certified Counselor (NCC)',
                          'National Certified School Counselor (NCSC)',
                          'Psychiatric Mental Health Nurse Practitioner (PMHNP)',
                          'Psychiatrist',
                          'Psychologist',
                          'Registered Art Therapist (ATR)',
                          'Registered Play Therapist (RPT)',
                          'Therapist']

    const therapyIssues = ["Abandonment",
                           "Abortion Issues",
                           "Academic Life",
                           "Acute Stress Disorder",
                           "Addiction",
                           "Adjustment Disorders ",
                           "Adoption",
                           "Aging (Geriatric)",
                           "Agoraphobia",
                           "Alcohol Use Disorder (AUD)",
                           "Alzheimer's Disease",
                           "Anger Management",
                           "Anorexia Nervosa",
                           "Antisocial Personality Disorder ",
                           "Asperger Syndrome",
                           "Attention-deficit Hyperactivity Disorder (ADHD, ADD)",
                           "Autism Spectrum Disorder",
                           "Avoidant Personality Disorder",
                           "Batterer Intervention",
                           "Behavioral Issues",
                           "Binge-eating Disorder ",
                           "Bipolar Disorder",
                           "Body Dysmorphic Disorder (BDD)",
                           "Borderline Personality Disorder",
                           "Breakup",
                           "Bulimia Nervosa",
                           "Bullying",
                           "Cancer",
                           "Cannabis Use Disorder",
                           "Career Counseling",
                           "Caregiver Issues",
                           "Child Or Adolescent",
                           "Childhood Onset Fluency Disorder (Stuttering)",
                           "Chronic Illness",
                           "Chronic Relapse",
                           "Claustrophobia",
                           "Codependency",
                           "Communication Disorders",
                           "Conduct Disorder",
                           "Conversion Disorder",
                           "Coping Skills",
                           "Delirium",
                           "Delusions",
                           "Dependent Personality Disorder",
                           "Depersonalization / derealization Disorder ",
                           "Depressive Disorders",
                           "Developmental Disorders",
                           "Disassociative Identity Disorder",
                           "Disruptive Disorders",
                           "Disruptive Mood Dysregulation Disorder",
                           "Dissociative Amnesia",
                           "Dissociative Disorders",
                           "Divorce",
                           "Domestic Abuse",
                           "Domestic Violence",
                           "Dual Diagnosis",
                           "Dyslexia",
                           "Elder Abuse",
                           "Emotional Abuse",
                           "Emotional Disturbance",
                           "Epilepsy",
                           "Erotomania",
                           "Excoriation Disorder (Skin Picking)",
                           "Exercise Addiction",
                           "Exhibitionism",
                           "Existential Crises",
                           "Factitious Disorder",
                           "Family Conflict",
                           "Forgiveness",
                           "Generalized Anxiety Disorder (GAD)",
                           "Grandiose Delusions",
                           "Grief",
                           "HIV / AIDS",
                           "Hallucinations",
                           "Histrionic Personality Disorder",
                           "Hoarding Disorder",
                           "Huntington's Disease",
                           "Hypersomnolence ",
                           "Hypochondriasis",
                           "Hysteria",
                           "Illness Anxiety Disorder",
                           "Impostor Syndrome",
                           "Impulse-Control Disorders",
                           "Infertility",
                           "Infidelity",
                           "Inhalant Use Disorder",
                           "Intellectual Disability (Intellectual Developmental Disorder)",
                           "Intermittent Explosive Disorder",
                           "Internet Addiction",
                           "Kleptomania",
                           "Language Disorders",
                           "Learning Disabilities",
                           "Life Coaching",
                           "Life Transitions",
                           "Major Depressive Disorder",
                           "Male Erectile Disorder",
                           "Mania",
                           "Marital And Premarital",
                           "Medical Detox",
                           "Medication Management",
                           "Megalomania",
                           "Melancholia",
                           "Men's Issues",
                           "Midlife Crisis",
                           "Military And Veterans Issues",
                           "Mood Disorders",
                           "Multicultural Issues",
                           "Munchausen's Syndrome",
                           "Narcissistic Personality",
                           "Narcissistic Personality Disorder",
                           "Narcolepsy (Encephalitis Lethargica)",
                           "Neurocognitive Disorders",
                           "Neurodevelopmental",
                           "Nightmare Disorder",
                           "Obesity",
                           "Obsessive Love Disorder",
                           "Obsessive-compulsive Disorder (OCD)",
                           "Obsessive-compulsive Personality Disorder",
                           "Obsessive-compulsive Personality Disorder (OCPD)",
                           "Oppositional Defiance",
                           "Oppositional Defiant Disorder (ODD)",
                           "Pain Disorder",
                           "Panic Disorder (Panic Attacks)",
                           "Paranoid Personality Disorder",
                           "Parenting",
                           "Parkinson's Disease",
                           "Pathological Gambling",
                           "Peer Relationships",
                           "Perfectionism",
                           "Persistent Depressive Disorder (Dysthymia)",
                           "Personality Disorders",
                           "Phobic Disorder (Spiders, Snakes, Heights, Small Spaces)",
                           "Pica",
                           "Polyamory / Nonmonogamous Relationships",
                           "Post-traumatic Stress Disorder (PTSD)",
                           "Pregnancy, Prenatal, Postpartum",
                           "Premenstrual Dysphoric Disorder",
                           "Psychotic Disorders",
                           "Pyromania",
                           "Racial Identity",
                           "Reactive Attachment Disorder",
                           "Relationship Issues",
                           "Restless Legs Syndrome",
                           "Retrograde Amnesia",
                           "Rumination Disorder ",
                           "Schizoid Personality Disorder",
                           "Schizophrenia",
                           "Schizotypal Personality Disorder",
                           "School Issues",
                           "Self-Care (Self-Compassion) Issues",
                           "Self-Esteem Issues",
                           "Self-Harm",
                           "Separation Anxiety Disorder",
                           "Sex Addiction",
                           "Sex Therapy",
                           "Sexual Abuse",
                           "Sexual Addiction",
                           "Sleep Disorders (Parasomnia, Insomnia)",
                           "Social Media",
                           "Social Anxiety Disorder",
                           "Social Communication Disorder",
                           "Somatoform Disorders (Somatic)",
                           "Speech Sound Disorder",
                           "Spirituality Or Religious Issues",
                           "Sports Performance",
                           "Stimulant Use Disorder",
                           "Stress",
                           "Substance Use Disorder",
                           "Suicide, suicidal Ideation",
                           "Teen Violence",
                           "Terminal Illness",
                           "Testing And Evaluation",
                           "Tobacco Use Disorder (Smoking, Nicotine Withdrawal)",
                           "Transgender (Gender Dysphoria)",
                           "Trauma",
                           "Traumatic Brain Injury",
                           "Trichotillomania (Hair-pulling)",
                           "Video Game Addiction",
                           "Weight Loss",
                           "Wilson's Disease",
                           "Women's Issues",
                           "Work Life",
                           "Young Adult Issues"]

    const approaches = [
                         {stringName: 'Acceptance and Commitment Therapy (ACT)', id: 'acceptance_and_commitment_therapy_(act)'},
                         {stringName: 'Adlerian Therapy', id: 'adlerian_therapy'},
                         {stringName: 'Animal-Assisted Therapy', id: 'animal-assisted_therapy'},
                         {stringName: 'Applied Behavior Analysis', id: 'applied_behavior_analysis'},
                         {stringName: 'Art Therapy', id: 'art_therapy'},
                         {stringName: 'Attachment-Based Therapy', id: 'attachment-based_therapy'},
                         {stringName: 'Biofeedback', id: 'biofeedback'},
                         {stringName: 'Brain Stimulation Therapy', id: 'brain_stimulation_therapy'},
                         {stringName: 'Client-Centered Therapy', id: 'client-centered_therapy'},
                         {stringName: 'Coaching', id: 'coaching'},
                         {stringName: 'Cognitive Behavioral Therapy (CBT)', id: 'cognitive_behavioral_therapy_(cbt)'},
                         {stringName: 'Cognitive Processing Therapy', id: 'cognitive_processing_therapy'},
                         {stringName: 'Cognitive Stimulation Therapy', id: 'cognitive_stimulation_therapy'},
                         {stringName: 'Compassion-Focused Therapy', id: 'compassion-focused_therapy'},
                         {stringName: 'Culturally Sensitive Therapy', id: 'culturally_sensitive_therapy'},
                         {stringName: 'Dialectical Behavior Therapy (DBT)', id: 'dialectical_behavior_therapy_(dbt)'},
                         {stringName: 'Eclectic Therapy', id: 'eclectic_therapy'},
                         {stringName: 'Emotionally Focused Therapy', id: 'emotionally_focused_therapy'},
                         {stringName: 'Existential Therapy', id: 'existential_therapy'},
                         {stringName: 'Experiential Therapy', id: 'experiential_therapy'},
                         {stringName: 'Expressive Arts Therapy', id: 'expressive_arts_therapy'},
                         {stringName: 'Eye Movement Desensitization and Reprocessing Therapy (EMDR)', id: 'eye_movement_desensitization_and_reprocessing_therapy_(emdr)'},
                         {stringName: 'Family Systems Therapy', id: 'family_systems_therapy'},
                         {stringName: 'Feminist Therapy', id: 'feminist_therapy'},
                         {stringName: 'Forensic Therapy', id: 'forensic_therapy'},
                         {stringName: 'Gestalt Therapy', id: 'gestalt_therapy'},
                         {stringName: 'Human Givens Therapy', id: 'human_givens_therapy'},
                         {stringName: 'Humanistic Therapy', id: 'humanistic_therapy'},
                         {stringName: 'Hypnotherapy', id: 'hypnotherapy'},
                         {stringName: 'Imago Relationship Therapy', id: 'imago_relationship_therapy'},
                         {stringName: 'Integrative Therapy', id: 'integrative_therapy'},
                         {stringName: 'Internal Family Systems Therapy', id: 'internal_family_systems_therapy'},
                         {stringName: 'Interpersonal Psychotherapy', id: 'interpersonal_psychotherapy'},
                         {stringName: 'Jungian Therapy', id: 'jungian_therapy'},
                         {stringName: 'Marriage and Family Therapy (MFT)', id: 'marriage_and_family_therapy_(mft)'},
                         {stringName: 'Mentalization-Based Therapy', id: 'mentalization-based_therapy'},
                         {stringName: 'Mindfulness-Based Cognitive Therapy', id: 'mindfulness-based_cognitive_therapy'},
                         {stringName: 'Motivational Interviewing', id: 'motivational_interviewing'},
                         {stringName: 'Multicultural Therapy', id: 'multicultural_therapy'},
                         {stringName: 'Narrative Therapy', id: 'narrative_therapy'},
                         {stringName: 'Neuro-Linguistic Programming Therapy', id: 'neuro-linguistic_programming_therapy'},
                         {stringName: 'Neurofeedback', id: 'neurofeedback'},
                         {stringName: 'Parent-Child Interaction Therapy (PCIT)', id: 'parent-child_interaction_therapy_(pcit)'},
                         {stringName: 'Person-Centered Therapy', id: 'person-centered_therapy'},
                         {stringName: 'Play Therapy', id: 'play_therapy'},
                         {stringName: 'Positive Psychology', id: 'positive_psychology'},
                         {stringName: 'Prolonged Exposure Therapy', id: 'prolonged_exposure_therapy'},
                         {stringName: 'Psychoanalytic Therapy', id: 'psychoanalytic_therapy'},
                         {stringName: 'Psychodynamic Therapy', id: 'psychodynamic_therapy'},
                         {stringName: 'Psychological Testing and Evaluation', id: 'psychological_testing_and_evaluation'},
                         {stringName: 'Rational Emotive Behavior Therapy', id: 'rational_emotive_behavior_therapy'},
                         {stringName: 'Reality Therapy', id: 'reality_therapy'},
                         {stringName: 'Relational Therapy', id: 'relational_therapy'},
                         {stringName: 'Sandplay Therapy', id: 'sandplay_therapy'},
                         {stringName: 'Social Recovery Therapy', id: 'social_recovery_therapy'},
                         {stringName: 'Solution-Focused Brief Therapy', id: 'solution-focused_brief_therapy'},
                         {stringName: 'Somatic Therapy', id: 'somatic_therapy'},
                         {stringName: 'Strength-Based Therapy', id: 'strength-based_therapy'},
                         {stringName: 'Structural Family Therapy', id: 'structural_family_therapy'},
                         {stringName: 'The Gottman Method', id: 'the_gottman_method'},
                         {stringName: 'Therapeutic Intervention', id: 'therapeutic_intervention'},
                         {stringName: 'Transpersonal Therapy', id: 'transpersonal_therapy'},
                         {stringName: 'Trauma-Focused Cognitive Behavior Therapy', id: 'trauma-focused_cognitive_behavior_therapy'}
                        ]

    const stateCodes = [{ stringName: 'Alabama', id: 'AL' },
                       { stringName: 'Alaska', id: 'AK' },
                       { stringName: 'American Samoa', id: 'AS' },
                       { stringName: 'Arizona', id: 'AZ' },
                       { stringName: 'Arkansas', id: 'AR' },
                       { stringName: 'California', id: 'CA' },
                       { stringName: 'Colorado', id: 'CO' },
                       { stringName: 'Connecticut', id: 'CT' },
                       { stringName: 'Delaware', id: 'DE' },
                       { stringName: 'District of Columbia', id: 'DC' },
                       { stringName: 'Federated States of Micronesia', id: 'FM' },
                       { stringName: 'Florida', id: 'FL' },
                       { stringName: 'Georgia', id: 'GA' },
                       { stringName: 'Guam', id: 'GU' },
                       { stringName: 'Hawaii', id: 'HI' },
                       { stringName: 'Idaho', id: 'ID' },
                       { stringName: 'Illinois', id: 'IL' },
                       { stringName: 'Indiana', id: 'IN' },
                       { stringName: 'Iowa', id: 'IA' },
                       { stringName: 'Kansas', id: 'KS' },
                       { stringName: 'Kentucky', id: 'KY' },
                       { stringName: 'Louisiana', id: 'LA' },
                       { stringName: 'Maine', id: 'ME' },
                       { stringName: 'Marshall Islands', id: 'MH' },
                       { stringName: 'Maryland', id: 'MD' },
                       { stringName: 'Massachusetts', id: 'MA' },
                       { stringName: 'Michigan', id: 'MI' },
                       { stringName: 'Minnesota', id: 'MN' },
                       { stringName: 'Mississippi', id: 'MS' },
                       { stringName: 'Missouri', id: 'MO' },
                       { stringName: 'Montana', id: 'MT' },
                       { stringName: 'Nebraska', id: 'NE' },
                       { stringName: 'Nevada', id: 'NV' },
                       { stringName: 'New Hampshire', id: 'NH' },
                       { stringName: 'New Jersey', id: 'NJ' },
                       { stringName: 'New Mexico', id: 'NM' },
                       { stringName: 'New York', id: 'NY' },
                       { stringName: 'North Carolina', id: 'NC' },
                       { stringName: 'North Dakota', id: 'ND' },
                       { stringName: 'Northern Mariana Islands', id: 'MP' },
                       { stringName: 'Ohio', id: 'OH' },
                       { stringName: 'Oklahoma', id: 'OK' },
                       { stringName: 'Oregon', id: 'OR' },
                       { stringName: 'Pennsylvania', id: 'PA' },
                       { stringName: 'Puerto Rico', id: 'PR' },
                       { stringName: 'Rhode Island', id: 'RI' },
                       { stringName: 'South Carolina', id: 'SC' },
                       { stringName: 'South Dakota', id: 'SD' },
                       { stringName: 'Tennessee', id: 'TN' },
                       { stringName: 'Texas', id: 'TX' },
                       { stringName: 'Utah', id: 'UT' },
                       { stringName: 'Vermont', id: 'VT' },
                       { stringName: 'Virgin Islands', id: 'VI' },
                       { stringName: 'Virginia', id: 'VA' },
                       { stringName: 'Washington', id: 'WA' },
                       { stringName: 'West Virginia', id: 'WV' },
                       { stringName: 'Wisconsin', id: 'WI' },
                       { stringName: 'Wyoming', id: 'WY' }]
    return(
      <Fragment>
        <Form {...formItemLayout} onSubmit={ e => this._handleOnSubmit(e)}>
          <Row>
            <Col span={18} offset={2}>

              <Divider orientation='left'>
                Your Credentials
              </Divider>

              <Form.Item label='When did you first start practicing?'>
              {
                getFieldDecorator(
                  'experience_time',
                  {
                    rules: [{
                        required: true,
                        message: 'Please select'
                      }]
                  }
                  )(
                    <MonthPicker onChange={this._onChangeExperienceTime} placeholder="Select month" />
                  )
              }
              </Form.Item>

              <Form.Item label='License'>
              {
                getFieldDecorator(
                  'license_status',
                  {
                    rules: [{
                        required: true,
                        message: 'Please select'
                      }]
                  }
                  )(
                    <Radio.Group style={{paddingTop: '5px'}}>
                      <Radio style={radioStyle} value={'F'}>Licensed (fully licensed, not under supervision)</Radio>
                      <Radio style={radioStyle} value={'S'}>Licensed under supervision (or pre-licensed)</Radio>
                      <Radio style={radioStyle} value={'N'}>None of the above</Radio>
                    </Radio.Group>
                  )
              }
              </Form.Item>
              <Form.Item label='License State'>
              {
                getFieldDecorator(
                  'license_state',
                  {
                    rules: [{
                        required: false,
                        message: 'Please select'
                      }]
                  }
                  )(
                    <Select style={{ width: 265 }}>
                    {
                      stateCodes.map( item => (<Option key={item.id} value={item.id}>{item.id} - {item.stringName}</Option>))
                    }
                    </Select>
                  )
              }
              </Form.Item>
              <Form.Item label='License number, ID, or code'>
              {
                getFieldDecorator(
                  'license_id',
                  {
                    rules: [{
                        required: false
                      }]
                  }
                  )(<Input />)
              }
              </Form.Item>
              <Form.Item label='License expiry date'>
              {
                getFieldDecorator(
                  'license_expiry_date',
                  {
                    rules: [{
                        required: false
                      }]
                  }
                  )(<DatePicker />)
              }
              </Form.Item>
              <Form.Item label='Are you licensed in multiple states?'>
              {
                getFieldDecorator(
                  'license_multiple',
                  {
                    rules: [{
                        required: true,
                        message: 'Please select'
                      }]
                  }
                  )(
                    <Radio.Group style={{paddingTop: '5px'}}>
                      <Radio style={radioStyle} value={'Y'}>Yes</Radio>
                      <Radio style={radioStyle} value={'N'}>No</Radio>
                    </Radio.Group>
                  )
              }
              <br />
              <small>If yes, we will email you to get the other state license details</small>
              </Form.Item>

              <Divider orientation='left'>
                Practice Information
              </Divider>

              <Form.Item label='Therapy Type'>
              {
                getFieldDecorator(
                  'therapyTypes',
                  {
                    rules: [{
                        required: true,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyTypes}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
                <small>If you have multiple types, please separate with a comma</small>
              </Form.Item>
              <Form.Item label='Name of Practice (Business Name)'>
              {
                getFieldDecorator(
                  'practice_name',
                  {
                    rules: [{
                        required: false,
                        whitespace: false,
                      }]
                  }
                  )(<Input />)
              }
              </Form.Item>
              <Form.Item label='Do you offer online therapy?'>
              {
                getFieldDecorator(
                  'online_therapy',
                  {
                    rules: [{
                        required: true,
                        message: 'Please select'
                      }]
                  }
                  )(
                    <Radio.Group style={{paddingTop: '5px'}}>
                      <Radio style={radioStyle} value={'Y'}>Yes, while still keeping in-person sessions</Radio>
                      <Radio style={radioStyle} value={'X'}>Yes, but exclusively (only online, no in-person sessions)</Radio>
                      <Radio style={radioStyle} value={'N'}>No</Radio>
                      <Radio style={radioStyle} value={'C'}>Considering (leaning 'yes')</Radio>
                      <Radio style={radioStyle} value={'M'}>Maybe (on the fence)</Radio>
                      <Radio style={radioStyle} value={'U'}>Unlikely</Radio>
                      <Radio style={radioStyle} value={'V'}>Never</Radio>
                    </Radio.Group>
                  )
              }
              <br />
              <small>This includes voice or audio -only (e.g. therapy over the telephone)</small>
              </Form.Item>

              <Divider orientation='left'>
                Issues, Disorders, Diseases, Syndromes
              </Divider>

              Please select all relevant issues that you work with.
              <br / >
              <br / >

              <Form.Item label='Issue #1'>
              {
                getFieldDecorator(
                  'issue1',
                  {
                    rules: [{
                        required: true,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>

              <Form.Item label='Issue #2'>
              {
                getFieldDecorator(
                  'issue2',
                  {
                    rules: [{
                        required: false,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>

              <Form.Item label='Issue #3'>
              {
                getFieldDecorator(
                  'issue3',
                  {
                    rules: [{
                        required: false,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>

              <Form.Item label='Issue #4'>
              {
                getFieldDecorator(
                  'issue4',
                  {
                    rules: [{
                        required: false,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>

              <Form.Item label='Issue #5'>
              {
                getFieldDecorator(
                  'issue5',
                  {
                    rules: [{
                        required: false,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>

              <Form.Item label='Issue #6'>
              {
                getFieldDecorator(
                  'issue6',
                  {
                    rules: [{
                        required: false,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>

              <Form.Item label='Issue #7'>
              {
                getFieldDecorator(
                  'issue7',
                  {
                    rules: [{
                        required: false,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>

              <Form.Item label='Issue #8'>
              {
                getFieldDecorator(
                  'issue8',
                  {
                    rules: [{
                        required: false,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>

              <Form.Item label='Issue #9'>
              {
                getFieldDecorator(
                  'issue9',
                  {
                    rules: [{
                        required: false,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>

              <Form.Item label='Issue #10'>
              {
                getFieldDecorator(
                  'issue10',
                  {
                    rules: [{
                        required: false,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>

              <Form.Item label='Issue #11'>
              {
                getFieldDecorator(
                  'issue11',
                  {
                    rules: [{
                        required: false,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>

              <Form.Item label='Issue #12'>
              {
                getFieldDecorator(
                  'issue12',
                  {
                    rules: [{
                        required: false,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>

              <Form.Item label='Issue #13'>
              {
                getFieldDecorator(
                  'issue13',
                  {
                    rules: [{
                        required: false,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>

              <Form.Item label='Issue #14'>
              {
                getFieldDecorator(
                  'issue14',
                  {
                    rules: [{
                        required: false,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>

              <Form.Item label='Issue #15'>
              {
                getFieldDecorator(
                  'issue15',
                  {
                    rules: [{
                        required: false,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>

              <Form.Item label='Issue #16'>
              {
                getFieldDecorator(
                  'issue16',
                  {
                    rules: [{
                        required: false,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>

              <Form.Item label='Issue #17'>
              {
                getFieldDecorator(
                  'issue17',
                  {
                    rules: [{
                        required: false,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>

              <Form.Item label='Issue #18'>
              {
                getFieldDecorator(
                  'issue18',
                  {
                    rules: [{
                        required: false,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>

              <Form.Item label='Issue #19'>
              {
                getFieldDecorator(
                  'issue19',
                  {
                    rules: [{
                        required: false,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>

              <Form.Item label='Issue #20'>
              {
                getFieldDecorator(
                  'issue20',
                  {
                    rules: [{
                        required: false,
                        min: 2,
                        whitespace: false,
                        message: 'If the dropdown doesn\'t have what you need, just type it out in full'
                      }]
                  }
                  )(
                    <AutoComplete
                      dataSource={therapyIssues}
                      placeholder="Start typing here"
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      } />
                  )
              }
              </Form.Item>



              <Form.Item label='Issue (Other)'>
              {
                getFieldDecorator(
                  'issue_other',
                  {
                    rules: [{
                        required: false
                      }]
                  }
                  )(<Input style={{ width: 300 }} />)
              }
              <br />
              <small>If an issue you work with is not listed above, please add it here. Separate with commas for multiple issues </small>
              </Form.Item>


              <Divider orientation='left'>
                Approach
              </Divider>

              Please select all relevant approaches. Some clients may have no understanding of these but for the ones who do and are looking for a specific approach, this will help them narrow down their search to find you.
              <br / >
              <br / >

              <Form.Item label='Approaches'>
              {
                getFieldDecorator(
                  'approaches',
                  {
                    rules: [{
                        required: false
                      }]
                  }
                  )(
                      <Checkbox.Group onChange={ this._onChangeApproachesCheckBox }>
                       <Row style={{ width: '450px'}}>
                        {
                          approaches.map( item => (<Col key={item.id} ><Checkbox value={item.id}>{item.stringName}</Checkbox></Col>) )
                        }
                       </Row>
                      </Checkbox.Group>
                  )
              }
              </Form.Item>

              <Form.Item label='Approach (Other)'>
              {
                getFieldDecorator(
                  'approach_other',
                  {
                    rules: [{
                        required: false
                      }]
                  }
                  )(<Input style={{ width: 300 }} />)
              }
              <br />
              <small>If your approach is not listed above, please add it here. Separate with commas for multiple approaches </small>
              </Form.Item>

              <Divider orientation='left'>
                General business hours
              </Divider>

              <Form.Item label='Monday'>
              {
                getFieldDecorator(
                  'hours_mon',
                  {
                    rules: [{
                        required: false
                      }]
                  }
                  )(<Input style={{ width: 300 }} />)
              }
              <br />
              <small>Example: 9:00am - 11:30am, 4:30pm - 7:30pm (comma separated time ranges)</small>
              </Form.Item>
              <Form.Item label='Tuesday'>
              {
                getFieldDecorator(
                  'hours_tue',
                  {
                    rules: [{
                        required: false
                      }]
                  }
                  )(<Input style={{ width: 300 }} />)
              }
              </Form.Item>
              <Form.Item label='Wednesday'>
              {
                getFieldDecorator(
                  'hours_wed',
                  {
                    rules: [{
                        required: false
                      }]
                  }
                  )(<Input style={{ width: 300 }} />)
              }
              </Form.Item>
              <Form.Item label='Thursday'>
              {
                getFieldDecorator(
                  'hours_thu',
                  {
                    rules: [{
                        required: false
                      }]
                  }
                  )(<Input style={{ width: 300 }} />)
              }
              </Form.Item>
              <Form.Item label='Friday'>
              {
                getFieldDecorator(
                  'hours_fri',
                  {
                    rules: [{
                        required: false
                      }]
                  }
                  )(<Input style={{ width: 300 }} />)
              }
              </Form.Item>
              <Form.Item label='Saturday'>
              {
                getFieldDecorator(
                  'hours_sat',
                  {
                    rules: [{
                        required: false
                      }]
                  }
                  )(<Input style={{ width: 300 }} />)
              }
              <br />
              <small>Leave blank if not open</small>
              </Form.Item>
              <Form.Item label='Sunday'>
              {
                getFieldDecorator(
                  'hours_sun',
                  {
                    rules: [{
                        required: false
                      }]
                  }
                  )(<Input style={{ width: 300 }} />)
              }
              </Form.Item>

              <Divider orientation='left'>
                Location Address
              </Divider>

              <Form.Item label='Address Line 1'>
              {
                getFieldDecorator(
                  'address_line_1',
                  {
                    rules: [{
                        required: true,
                        whitespace: false,
                        min: 1,
                        message: 'Required'
                      }]
                  }
                  )(<Input />)
              }
              </Form.Item>
              <Form.Item label='Address Line 2'>
              {
                getFieldDecorator(
                  'address_line_2',
                  {
                    rules: [{
                        required: false,
                        // whitespace: false,
                        // min: 1,
                        // message: 'Too short?'
                      }]
                  }
                  )(<Input />)
              }
              </Form.Item>
              <Form.Item label='City'>
              {
                getFieldDecorator(
                  'city',
                  {
                    rules: [{
                        required: true,
                        whitespace: false,
                        min: 2,
                        message: 'Required'
                      }]
                  }
                  )(<Input />)
              }
              </Form.Item>
              <Form.Item label='State'>
              {
                getFieldDecorator(
                  'state_code',
                  {
                    rules: [{
                        required: true,
                        message: 'Please select'
                      }]
                  }
                  )(
                    <Select style={{ width: 265 }}>
                    {
                      stateCodes.map( item => (<Option key={item.id} value={item.id}>{item.id} - {item.stringName}</Option>))
                    }
                    </Select>
                  )
              }
              </Form.Item>
              <Form.Item label='Zip'>
              {
                getFieldDecorator(
                  'zip',
                  {
                    rules: [{
                        required: true,
                        whitespace: false,
                        min: 5,
                        message: 'Required'
                      },{
                        max: 10,
                        message: 'Too long'
                      }
                      ]
                  }
                  )(<Input style={{ width: 120 }} />)
              }
              </Form.Item>
              <Form.Item label='Do you practice from multiple locations?'>
              {
                getFieldDecorator(
                  'multiple_locations',
                  {
                    rules: [{
                        required: true,
                        message: 'Please select'
                      }]
                  }
                  )(
                    <Radio.Group style={{paddingTop: '5px'}}>
                      <Radio style={radioStyle} value={'Y'}>Yes</Radio>
                      <Radio style={radioStyle} value={'N'}>No</Radio>
                    </Radio.Group>
                  )
              }
              <br />
              <small>If yes, we will email you to get the other addresses</small>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col offset={4} span={16}>
              <Form.Item>
                <Button type='primary'
                  htmlType='submit'
                  disabled={this._hasErrors(getFieldsError())}
                >
                  Next
                </Button>
              </Form.Item>
            </Col>
          </Row>

{/*
          <Row>
            <Col span={24}>
              <Query query={ GET_SIGNUP_PRACTICE_INFO }>
              {
                ({ data }) => {
                  if(data){
                    // this is just to test that the data did get stored into local apollo cache
                    //console.log(data.signupApproaches.value)
                    return (
                        <div>
                          signupTherapyTypes: {data.signupTherapyTypes.value} <br />
                          signupExperienceTime: {data.signupExperienceTime.value} <br />
                        </div>
                      )
                  }else{
                    return null
                  }
                }
              }
              </Query>
            </Col>
          </Row>
*/}

        </Form>
      </Fragment>
      ) // return
  } // render
} // class

export default Form.create({name: 'signuppracticeform_hoc'})(SignupPracticeForm)
