import React, { Component, Fragment } from 'react'
import './App.css'
import gql from 'graphql-tag'

import { BrowserRouter, Link, Route, Switch, Redirect } from 'react-router-dom'

import { Layout, Menu, message } from 'antd'
import 'antd/dist/antd.css'

import { __log } from './utils/log.js'

import HorizontalLoginForm from './components/HorizontalLoginForm.js'
import MentalModelAddComponent from './components/MentalModelAddComponent.js'
import HomeComponent from './components/HomeComponent.js'
import ThoughtDetailComponent from './components/ThoughtDetailComponent.js'
import MentalModelDetailComponent from './components/MentalModelDetailComponent.js'
import SignupComponent from './components/SignupComponent.js'

const { Header, Content, Footer } = Layout // antd

const JWT_TOKEN_CHECK = gql`
    query {
      me {
        username
      }
    }
`
/*
const PrivateRoute = ({component: Component, ...rest}) => {
  __log(`private route isLoggedIn: ${rest.isLoggedIn}`)
  return (
      <Route {...rest} render={(props) => (
        rest.isLoggedIn === true
        ?  <Component {...props} />
        : <Redirect to='/login' />
        )} />
    )}
*/

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      mentalModel: '',
      isLoggedIn: false,
      initialLoginCheckPending: true
    }
    this.isLoggedInCheck = this.isLoggedInCheck.bind(this)
    this._handleLogout = this._handleLogout.bind(this)
  }

  _handleLogout = (props) => {
    localStorage.removeItem('LocalStorageAuthTokenKeyName')
    this.setState({isLoggedIn: false})
    message.success(`Success! You are logged out.`)
  }

  _confirmAddMentalModel = async (data) => {
    __log(`_confirmAddMentalModel: id: ${data.addMentalModel.id} text: ${data.addMentalModel.text}`)
  }

  async isLoggedInCheck({verifyTokenFromServer = false} = {}){
    // WARNING. This looks similar to the similar function in /utils/
    // but it is subtly different enough! In particular:
    // what happens upon handling success/failure post-auth attempt
    __log(`App.js: isLoggedInCheck() verifyTokenFromServer: ${verifyTokenFromServer}`)
    if(verifyTokenFromServer){ // incurs a db query every single time
      try {
        const results = await this.props.client.query({ query: JWT_TOKEN_CHECK })
        __log(`App.js: isLoggedInCheck() received data from backend: `, results.data.me)
        if(results.data.me.username.length > 0 && typeof(results.data.me.username) === 'string'){
          __log(`App.js: isLoggedInCheck() setting isLoggedIn = true`)
          this.setState({isLoggedIn: true})
        }else{
          // this block should not be reached
          __log(`App.js: ERR. SHOULD NOT BE REACHED. isLoggedInCheck() setting isLoggedIn = false and removing JWT`)
          this.setState({isLoggedIn: false})
          localStorage.removeItem('LocalStorageAuthTokenKeyName')
        }
      }catch(e) { // if JWT error due to invalid token, this block will fire
        //__log(`App.js: username from db failed with exception: ${e}`)
        __log(`App.js: isLoggedInCheck() exception catch block after backend request. Setting isLoggedIn = false and removing JWT`)
        this.setState({isLoggedIn: false})
        localStorage.removeItem('LocalStorageAuthTokenKeyName')
      }
    }else{
      const jwt = localStorage.getItem('LocalStorageAuthTokenKeyName')
      if (jwt === null || jwt.toString() === 'null'){
        this.setState({isLoggedIn: false, initialLoginCheckPending: false}, () => {
          __log(`App.js: isLoggedInCheck() local token check. Setting isLoggedIn = false`)
        })
        localStorage.removeItem('LocalStorageAuthTokenKeyName')
      }else{
        this.setState({isLoggedIn: true, initialLoginCheckPending: false}, () => {
          __log(`App.js: isLoggedInCheck() local token check. Setting isLoggedIn = true`)
        })
      }
    }
  }

  componentDidMount(){
    // don't then(), see isLoggedInCheck() warning above
    this.isLoggedInCheck({verifyTokenFromServer: false})
  }

  render() {
    // if logged in, show logout 'button'. otherwise, do the opposite
    const loginOrLogoutMenuItem = this.state.isLoggedIn ?
      <Menu.Item key="3"><Link to={`/login`} onClick={this._handleLogout}>Logout</Link></Menu.Item> :
      <Menu.Item key="3"><Link to={`/login`}>Login</Link></Menu.Item>

    const addMentalModelMenuItem = this.state.isLoggedIn ?
    <Menu.Item key="2"><Link to={`/add`}>Add</Link></Menu.Item> :
    null

    return (
      <BrowserRouter>
        <Layout className="layout">
          <Header>
            <div className="logo" />
            <Route
              children={
                ({match, location }) => {
                  let selectedTab = '1'
                  if(location.pathname.indexOf('/login') === 0 && !this.state.isLoggedIn){
                    selectedTab = '3' // only show login page if not yet logged in
                  }else if(location.pathname.indexOf('/login') === 0 && this.state.isLoggedIn){
                    return <Redirect to="/" /> // if already logged in, send to main page
                  }else if(location.pathname.indexOf('/add') === 0){
                    selectedTab = '2'
                  }else if(location.pathname.indexOf('/signup') === 0){
                    selectedTab = '4'
                  }else if(location.pathname.indexOf('/thought/') === 0){
                    selectedTab = null
                  }else if(location.pathname.indexOf('/mentalmodel/') === 0){
                    selectedTab = null
                  }else if(location.pathname.indexOf('/') === 0 && location.pathname.length === 1){
                    selectedTab = '1'
                  }
                  return (
                      <Fragment>
                        <Menu
                          theme="dark"
                          mode="horizontal"
                          selectedKeys={[selectedTab]}
                          style={{ lineHeight: '64px' }}
                        >
                          <Menu.Item key="1"><Link to={`/`}>Home</Link></Menu.Item>
                          { addMentalModelMenuItem }
                          { loginOrLogoutMenuItem }
                          {
                            !this.state.isLoggedIn
                            ? <Menu.Item key="4"><Link to={`/signup`}>Sign Up</Link></Menu.Item>
                            : null
                          }
                        </Menu>
                      </Fragment>
                    )
                }
              }
            />
          </Header>
          <Content style={{ padding: '0 15px' }}>
            <div style={{ background: '#fff', padding: 12, minHeight: 280 }}>

              <div id='container'>
              <Switch>
                <Route path='/thought/:thoughtId' render={(props) => <ThoughtDetailComponent {...props} initialLoginCheckPending={this.state.initialLoginCheckPending} isLoggedIn={this.state.isLoggedIn} />}>
                </Route>
              {/*
                <PrivateRoute path='/thought/:thoughtId' isLoggedIn={this.state.isLoggedIn} component={ThoughtDetailComponent}>
                </PrivateRoute>
              */}
                <Route path='/mentalmodel/:mentalModelId' render={(props) => <MentalModelDetailComponent {...props} initialLoginCheckPending={this.state.initialLoginCheckPending} isLoggedIn={this.state.isLoggedIn} />}>
                </Route>
                <Route path='/login' render={(props) => <HorizontalLoginForm {...props} isLoggedInCheck={this.isLoggedInCheck} />}>
                </Route>
                <Route path='/add' render={(props) => <MentalModelAddComponent {...props} isLoggedIn={this.state.isLoggedIn} />}>
                </Route>
                <Route path='/signup' render={(props) => <SignupComponent {...props} />}>
                </Route>
                <Route path='/' render={(props) => <HomeComponent {...props} isLoggedIn={this.state.isLoggedIn} />}>
                </Route>
              </Switch>

              </div> {/* id = 'container' */}

            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Haute Technologies 2020</Footer>
        </Layout>
      </BrowserRouter>
    )
  }
}

export default App
