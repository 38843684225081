import LOG_ENABLED from '../config/log_level.constant.js'

export function __log () {
  let outputString = '' // eslint-disable-line no-unused-vars
  outputString += '--OK '
  let arr = Array.from(arguments)
  if (arr != null) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i]) {
        outputString += arr[i].toString()
      } else {
        outputString += 'null'
      }
      outputString += ' '
    }
  }
  if(LOG_ENABLED === true){
    console.log(outputString)
  }
}
