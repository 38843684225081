import { __log } from './log.js'

const isLoggedInCheck = async function ({verifyTokenFromServer = false} = {}){
    //__log(`utils/isLoggedInCheck() verifyTokenFromServer: ${verifyTokenFromServer}`)
    if(verifyTokenFromServer){ // incurs a db query every single time
      // @NA TODO this section is low priority.
      // Not needed unless components need to check if a user is logged in
      // need to query actual backend (i.e. testing existence of a JWT string in local
      // storage is not enough)
      /*
      try {
        const results = await this.props.client.query({ query: JWT_TOKEN_CHECK })
        __log(`isLoggedInCheck() received data from backend: `, results.data.me)
        if(results.data.me.username.length > 0 && typeof(results.data.me.username) === 'string'){
          __log(`isLoggedInCheck() setting isLoggedIn = true`)
          this.setState({isLoggedIn: true})
        }else{
          // this block should not be reached
          __log(`ERR. SHOULD NOT BE REACHED. isLoggedInCheck() setting isLoggedIn = false and removing JWT`)
          this.setState({isLoggedIn: false})
          localStorage.removeItem('LocalStorageAuthTokenKeyName')
        }
      }catch(e) { // if JWT error due to invalid token, this block will fire
        //__log(`username from db failed with exception: ${e}`)
        __log(`isLoggedInCheck() exception catch block after backend request. Setting isLoggedIn = false and removing JWT`)
        this.setState({isLoggedIn: false})
        localStorage.removeItem('LocalStorageAuthTokenKeyName')
      }
      */
    }else{
      const jwt = localStorage.getItem('LocalStorageAuthTokenKeyName')
      if (jwt === null || jwt.toString() === 'null'){
        localStorage.removeItem('LocalStorageAuthTokenKeyName')
        __log(`utils/isLoggedInCheck() return false`)
        return false
      }else{
        __log(`utils/isLoggedInCheck() return true`)
        return true
      }
    }
  }

export { isLoggedInCheck }
