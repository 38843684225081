import React, { Fragment } from 'react'
import { Row, Col, message } from 'antd'
import { Redirect } from 'react-router-dom'
import MentalModelForm from './MentalModelForm.js'

const MentalModelAddComponent = (props) => {
  if (props.isLoggedIn === false){
    message.error(`You must be logged in first`)
    return <Redirect to='/login' />
  }
  return ( <Fragment>
                <Row>
                  <Col span={24}>
                    <br />
                    <h1>Mental Model</h1>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <MentalModelForm />
                  </Col>
                </Row>
           </Fragment>
    )
}

export default MentalModelAddComponent
