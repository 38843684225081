import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
////import * as serviceWorker from './serviceWorker';

import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter } from 'react-router-dom'
import { withClientState } from 'apollo-link-state' // this may be deprecated

import API_PATH from './config/api_path.constant.js'

const httpLink = createHttpLink({
  uri: API_PATH
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('LocalStorageAuthTokenKeyName')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
})

const cache = new InMemoryCache()

const defaults = {
  signupFirstName: {
    value: '',
    __typename: 'SignupFirstName'
  },
  signupMiddleName: {
    value: '',
    __typename: 'SignupMiddleName'
  },
  signupLastName: {
    value: '',
    __typename: 'SignupLastName'
  },
  signupTitle: {
    value: '',
    __typename: 'SignupTitle'
  },
  signupEmail: {
    value: '',
    __typename: 'SignupEmail'
  },
  signupPhone1: {
    value: '',
    __typename: 'SignupPhone1'
  },
  signupPhone1Sms: {
    value: false,
    __typename: 'SignupPhone1Sms'
  },
  signupPassword: {
    value: '',
    __typename: 'SignupPassword'
  },

  // page 2 of signup flow below

  signupAddressLine1: {
    value: '',
    __typename: 'SignupAddressLine1'
  }, // ==> address_line_1
  signupAddressLine2: {
    value: '',
    __typename: 'SignupAddressLine2'
  }, // ==> address_line_2
  signupApproachOther: {
    value: '',
    __typename: 'SignupApproachOther'
  }, // ==> approach_other
  signupApproaches: {
    value: [],
    __typename: 'SignupApproaches'
  }, // ==> approaches
  signupCity: {
    value: '',
    __typename: 'SignupCity'
  }, // ==> city
  signupExperienceTime: {
    value: null, // can't store date time object here.
                 // save as string instead
    __typename: 'SignupExperienceTime'
  }, // ==> experience_time
  signupHoursFri: {
    value: '',
    __typename: 'SignupHoursFri'
  }, // ==> hours_fri
  signupHoursMon: {
    value: '',
    __typename: 'SignupHoursMon'
  }, // ==> hours_mon
  signupHoursSat: {
    value: '',
    __typename: 'SignupHoursSat'
  }, // ==> hours_sat
  signupHoursSun: {
    value: '',
    __typename: 'SignupHoursSun'
  }, // ==> hours_sun
  signupHoursThu: {
    value: '',
    __typename: 'SignupHoursThu'
  }, // ==> hours_thu
  signupHoursTue: {
    value: '',
    __typename: 'SignupHoursTue'
  }, // ==> hours_tue
  signupHoursWed: {
    value: '',
    __typename: 'SignupHoursWed'
  }, // ==> hours_wed
  signupIssue1: {
    value: '',
    __typename: 'SignupIssue1'
  }, // ==> issue1
  signupIssue2: {
    value: '',
    __typename: 'SignupIssue2'
  }, // ==> issue2
  signupIssue3: {
    value: '',
    __typename: 'SignupIssue3'
  }, // ==> issue3
  signupIssue4: {
    value: '',
    __typename: 'SignupIssue4'
  }, // ==> issue4
  signupIssue5: {
    value: '',
    __typename: 'SignupIssue5'
  }, // ==> issue5
  signupIssue6: {
    value: '',
    __typename: 'SignupIssue6'
  }, // ==> issue6
  signupIssue7: {
    value: '',
    __typename: 'SignupIssue7'
  }, // ==> issue7
  signupIssue8: {
    value: '',
    __typename: 'SignupIssue8'
  }, // ==> issue8
  signupIssue9: {
    value: '',
    __typename: 'SignupIssue9'
  }, // ==> issue9
  signupIssue10: {
    value: '',
    __typename: 'SignupIssue10'
  }, // ==> issue10
  signupIssue11: {
    value: '',
    __typename: 'SignupIssue11'
  }, // ==> issue11
  signupIssue12: {
    value: '',
    __typename: 'SignupIssue12'
  }, // ==> issue12
  signupIssue13: {
    value: '',
    __typename: 'SignupIssue13'
  }, // ==> issue13
  signupIssue14: {
    value: '',
    __typename: 'SignupIssue14'
  }, // ==> issue14
  signupIssue15: {
    value: '',
    __typename: 'SignupIssue15'
  }, // ==> issue15
  signupIssue16: {
    value: '',
    __typename: 'SignupIssue16'
  }, // ==> issue16
  signupIssue17: {
    value: '',
    __typename: 'SignupIssue17'
  }, // ==> issue17
  signupIssue18: {
    value: '',
    __typename: 'SignupIssue18'
  }, // ==> issue18
  signupIssue19: {
    value: '',
    __typename: 'SignupIssue19'
  }, // ==> issue19
  signupIssue20: {
    value: '',
    __typename: 'SignupIssue20'
  }, // ==> issue20
  signupIssueOther: {
    value: '',
    __typename: 'SignupIssueOther'
  }, // ==> issue_other
  signupLicenseExpiryDate: {
    value: null,
    __typename: 'SignupLicenseExpiryDate' // (Moment obj)
  }, // ==> license_expiry_date
  signupLicenseId: {
    value: '',
    __typename: 'SignupLicenseId'
  }, // ==> license_id
  signupLicenseMultiple: {
    value: '',
    __typename: 'SignupLicenseMultiple'
  }, // ==> license_multiple
  signupLicenseState: {
    value: '',
    __typename: 'SignupLicenseState'
  }, // ==> license_state
  signupLicenseStatus: {
    value: '',
    __typename: 'SignupLicenseStatus'
  }, // ==> license_status
  signupMultipleLocations: {
    value: '',
    __typename: 'SignupMultipleLocations'
  }, // ==> multiple_locations
  signupOnlineTherapy: {
    value: '',
    __typename: 'SignupOnlineTherapy'
  }, // ==> online_therapy
  signupPracticeName: {
    value: '',
    __typename: 'SignupPracticeName'
  }, // ==> practice_name
  signupStateCode: {
    value: '',
    __typename: 'SignupStateCode'
  }, // ==> state_code
  signupTherapyTypes: {
    value: '',
    __typename: 'SignupTherapyTypes'
  }, // ==> therapyTypes
  signupZip: {
    value: '',
    __typename: 'SignupZip'
  }, // ==> zip

} // const defaults

const resolvers = {
  Mutation: {
    setSignupPersonalInfo: ( _,
                            { signupFirstName,
                              signupMiddleName,
                              signupLastName,
                              signupTitle,
                              signupEmail,
                              signupPhone1,
                              signupPhone1Sms,
                              signupPassword
                            },
                            { cache }) => {
                              const newSignupFirstName = {
                                value: signupFirstName,
                                __typename: 'SignupFirstName'
                              }
                              const newSignupMiddleName = {
                                value: signupMiddleName,
                                __typename: 'SignupMiddleName'
                              }
                              const newSignupLastName = {
                                value: signupLastName,
                                __typename: 'SignupLastName'
                              }
                              const newSignupTitle = {
                                value: signupTitle,
                                __typename: 'SignupTitle'
                              }
                              const newSignupEmail = {
                                value: signupEmail,
                                __typename: 'SignupEmail'
                              }
                              const newSignupPhone1 = {
                                value: signupPhone1,
                                __typename: 'SignupPhone1'
                              }
                              const newSignupPhone1Sms = {
                                value: signupPhone1Sms,
                                __typename: 'SignupPhone1Sms'
                              }
                              const newSignupPassword = {
                                value: signupPassword,
                                __typename: 'SignupPassword'
                              }
                              const data = {
                                  signupFirstName: newSignupFirstName,
                                  signupMiddleName: newSignupMiddleName,
                                  signupLastName: newSignupLastName,
                                  signupTitle: newSignupTitle,
                                  signupEmail: newSignupEmail,
                                  signupPhone1: newSignupPhone1,
                                  signupPhone1Sms: newSignupPhone1Sms,
                                  signupPassword: newSignupPassword,
                                }
                              cache.writeData({ data })
                              return null // must return null
                                          // returning anything other than null seems to trigger
                                          // error message: Missing field [mutationName] in {}
    }, // setSignupPersonalInfo

    setSignupPracticeInfo: ( _,
                            {
                              signupAddressLine1,
                              signupAddressLine2,
                              signupApproachOther,
                              signupApproaches,
                              signupCity,
                              signupExperienceTime,
                              signupHoursFri,
                              signupHoursMon,
                              signupHoursSat,
                              signupHoursSun,
                              signupHoursThu,
                              signupHoursTue,
                              signupHoursWed,
                              signupIssue1,
                              signupIssue2,
                              signupIssue3,
                              signupIssue4,
                              signupIssue5,
                              signupIssue6,
                              signupIssue7,
                              signupIssue8,
                              signupIssue9,
                              signupIssue10,
                              signupIssue11,
                              signupIssue12,
                              signupIssue13,
                              signupIssue14,
                              signupIssue15,
                              signupIssue16,
                              signupIssue17,
                              signupIssue18,
                              signupIssue19,
                              signupIssue20,
                              signupIssueOther,
                              signupLicenseExpiryDate,
                              signupLicenseId,
                              signupLicenseMultiple,
                              signupLicenseState,
                              signupLicenseStatus,
                              signupMultipleLocations,
                              signupOnlineTherapy,
                              signupPracticeName,
                              signupStateCode,
                              signupTherapyTypes,
                              signupZip
                            },
                            { cache }) => {
                              let data = {}

                              // start by adding mandatory fields first
                              const newSignupAddressLine1 = {
                                value: signupAddressLine1,
                                __typename: 'SignupAddressLine1'
                              }
                              data['signupAddressLine1'] = newSignupAddressLine1

                              const newSignupApproaches = {
                                  value: signupApproaches || [],
                                  __typename: 'SignupApproaches'
                              }
                              data['signupApproaches'] = newSignupApproaches

                              const newSignupExperienceTime = {
                                  value: signupExperienceTime,
                                  __typename: 'SignupExperienceTime'
                              }
                              data['signupExperienceTime'] = newSignupExperienceTime

                              if(signupAddressLine2){
                                data['signupAddressLine2'] = {
                                  value: signupAddressLine2,
                                  __typename: 'SignupAddressLine2'
                                }
                              }
                              if(signupApproachOther){
                                data['signupApproachOther'] = {
                                  value: signupApproachOther,
                                  __typename: 'SignupApproachOther'
                                }
                              }
                              if(signupCity){
                                data['signupCity'] = {
                                  value: signupCity,
                                  __typename: 'SignupCity'
                                }
                              }
                              if(signupHoursFri){
                                data['signupHoursFri'] = {
                                  value: signupHoursFri,
                                  __typename: 'SignupHoursFri'
                                }
                              }
                              if(signupHoursMon){
                                data['signupHoursMon'] = {
                                  value: signupHoursMon,
                                  __typename: 'SignupHoursMon'
                                }
                              }
                              if(signupHoursSat){
                                data['signupHoursSat'] = {
                                  value: signupHoursSat,
                                  __typename: 'SignupHoursSat'
                                }
                              }
                              if(signupHoursSun){
                                data['signupHoursSun'] = {
                                  value: signupHoursSun,
                                  __typename: 'SignupHoursSun'
                                }
                              }
                              if(signupHoursThu){
                                data['signupHoursThu'] = {
                                  value: signupHoursThu,
                                  __typename: 'SignupHoursThu'
                                }
                              }
                              if(signupHoursTue){
                                data['signupHoursTue'] = {
                                  value: signupHoursTue,
                                  __typename: 'SignupHoursTue'
                                }
                              }
                              if(signupHoursWed){
                                data['signupHoursWed'] = {
                                  value: signupHoursWed,
                                  __typename: 'SignupHoursWed'
                                }
                              }
                              if(signupIssue1){
                                data['signupIssue1'] = {
                                  value: signupIssue1,
                                  __typename: 'SignupIssue1'
                                }
                              }
                              if(signupIssue2){
                                data['signupIssue2'] = {
                                  value: signupIssue2,
                                  __typename: 'SignupIssue2'
                                }
                              }
                              if(signupIssue3){
                                data['signupIssue3'] = {
                                  value: signupIssue3,
                                  __typename: 'SignupIssue3'
                                }
                              }
                              if(signupIssue4){
                                data['signupIssue4'] = {
                                  value: signupIssue4,
                                  __typename: 'SignupIssue4'
                                }
                              }
                              if(signupIssue5){
                                data['signupIssue5'] = {
                                  value: signupIssue5,
                                  __typename: 'SignupIssue5'
                                }
                              }
                              if(signupIssue6){
                                data['signupIssue6'] = {
                                  value: signupIssue6,
                                  __typename: 'SignupIssue6'
                                }
                              }
                              if(signupIssue7){
                                data['signupIssue7'] = {
                                  value: signupIssue7,
                                  __typename: 'SignupIssue7'
                                }
                              }
                              if(signupIssue8){
                                data['signupIssue8'] = {
                                  value: signupIssue8,
                                  __typename: 'SignupIssue8'
                                }
                              }
                              if(signupIssue9){
                                data['signupIssue9'] = {
                                  value: signupIssue9,
                                  __typename: 'SignupIssue9'
                                }
                              }
                              if(signupIssue10){
                                data['signupIssue10'] = {
                                  value: signupIssue10,
                                  __typename: 'SignupIssue10'
                                }
                              }
                              if(signupIssue11){
                                data['signupIssue11'] = {
                                  value: signupIssue11,
                                  __typename: 'SignupIssue11'
                                }
                              }
                              if(signupIssue12){
                                data['signupIssue12'] = {
                                  value: signupIssue12,
                                  __typename: 'SignupIssue12'
                                }
                              }
                              if(signupIssue13){
                                data['signupIssue13'] = {
                                  value: signupIssue13,
                                  __typename: 'SignupIssue13'
                                }
                              }
                              if(signupIssue14){
                                data['signupIssue14'] = {
                                  value: signupIssue14,
                                  __typename: 'SignupIssue14'
                                }
                              }
                              if(signupIssue15){
                                data['signupIssue15'] = {
                                  value: signupIssue15,
                                  __typename: 'SignupIssue15'
                                }
                              }
                              if(signupIssue16){
                                data['signupIssue16'] = {
                                  value: signupIssue16,
                                  __typename: 'SignupIssue16'
                                }
                              }
                              if(signupIssue17){
                                data['signupIssue17'] = {
                                  value: signupIssue17,
                                  __typename: 'SignupIssue17'
                                }
                              }
                              if(signupIssue18){
                                data['signupIssue18'] = {
                                  value: signupIssue18,
                                  __typename: 'SignupIssue18'
                                }
                              }
                              if(signupIssue19){
                                data['signupIssue19'] = {
                                  value: signupIssue19,
                                  __typename: 'SignupIssue19'
                                }
                              }
                              if(signupIssue20){
                                data['signupIssue20'] = {
                                  value: signupIssue20,
                                  __typename: 'SignupIssue20'
                                }
                              }
                              if(signupIssueOther){
                                data['signupIssueOther'] = {
                                  value: signupIssueOther,
                                  __typename: 'SignupIssueOther'
                                }
                              }
                              if(signupLicenseExpiryDate){
                                data['signupLicenseExpiryDate'] = {
                                  value: signupLicenseExpiryDate,
                                  __typename: 'SignupLicenseExpiryDate'
                                }
                              }
                              if(signupLicenseId){
                                data['signupLicenseId'] = {
                                  value: signupLicenseId,
                                  __typename: 'SignupLicenseId'
                                }
                              }
                              if(signupLicenseMultiple){
                                data['signupLicenseMultiple'] = {
                                  value: signupLicenseMultiple,
                                  __typename: 'SignupLicenseMultiple'
                                }
                              }
                              if(signupLicenseState){
                                data['signupLicenseState'] = {
                                  value: signupLicenseState,
                                  __typename: 'SignupLicenseState'
                                }
                              }
                              if(signupLicenseStatus){
                                data['signupLicenseStatus'] = {
                                  value: signupLicenseStatus,
                                  __typename: 'SignupLicenseStatus'
                                }
                              }
                              if(signupMultipleLocations){
                                data['signupMultipleLocations'] = {
                                  value: signupMultipleLocations,
                                  __typename: 'SignupMultipleLocations'
                                }
                              }
                              if(signupOnlineTherapy){
                                data['signupOnlineTherapy'] = {
                                  value: signupOnlineTherapy,
                                  __typename: 'SignupOnlineTherapy'
                                }
                              }
                              if(signupPracticeName){
                                data['signupPracticeName'] = {
                                  value: signupPracticeName,
                                  __typename: 'SignupPracticeName'
                                }
                              }
                              if(signupStateCode){
                                data['signupStateCode'] = {
                                  value: signupStateCode,
                                  __typename: 'SignupStateCode'
                                }
                              }
                              if(signupTherapyTypes){
                                data['signupTherapyTypes'] = {
                                  value: signupTherapyTypes,
                                  __typename: 'SignupTherapyTypes'
                                }
                              }
                              if(signupZip){
                                data['signupZip'] = {
                                  value: signupZip,
                                  __typename: 'SignupZip'
                                }
                              }

                              /*
                              const data = {
                                  signupAddressLine1: newSignupAddressLine1,
                                  signupAddressLine2: newSignupAddressLine2,
                                  signupApproachOther: newSignupApproachOther,
                                  signupApproaches: newSignupApproaches,
                                  signupCity: newSignupCity,
                                  // signupExperienceTime: newSignupExperienceTime,
                                  signupHoursFri: newSignupHoursFri,
                                  signupHoursMon: newSignupHoursMon,
                                  signupHoursSat: newSignupHoursSat,
                                  signupHoursSun: newSignupHoursSun,
                                  signupHoursThu: newSignupHoursThu,
                                  signupHoursTue: newSignupHoursTue,
                                  signupHoursWed: newSignupHoursWed,
                                  signupIssue1: newSignupIssue1,
                                  signupIssue2: newSignupIssue2,
                                  signupIssue3: newSignupIssue3,
                                  signupIssue4: newSignupIssue4,
                                  signupIssue5: newSignupIssue5,
                                  signupIssue6: newSignupIssue6,
                                  signupIssue7: newSignupIssue7,
                                  signupIssue8: newSignupIssue8,
                                  signupIssue9: newSignupIssue9,
                                  signupIssue10: newSignupIssue10,
                                  signupIssue11: newSignupIssue11,
                                  signupIssue12: newSignupIssue12,
                                  signupIssue13: newSignupIssue13,
                                  signupIssue14: newSignupIssue14,
                                  signupIssue15: newSignupIssue15,
                                  signupIssue16: newSignupIssue16,
                                  signupIssue17: newSignupIssue17,
                                  signupIssue18: newSignupIssue18,
                                  signupIssue19: newSignupIssue19,
                                  signupIssue20: newSignupIssue20,
                                  signupIssueOther: newSignupIssueOther,
                                  // signupLicenseExpiryDate: newSignupLicenseExpiryDate,
                                  signupLicenseId: newSignupLicenseId,
                                  signupLicenseMultiple: newSignupLicenseMultiple,
                                  signupLicenseState: newSignupLicenseState,
                                  signupLicenseStatus: newSignupLicenseStatus,
                                  signupMultipleLocations: newSignupMultipleLocations,
                                  signupOnlineTherapy: newSignupOnlineTherapy,
                                  signupPracticeName: newSignupPracticeName,
                                  signupStateCode: newSignupStateCode,
                                  signupTherapyTypes: newSignupTherapyTypes,
                                  signupZip: newSignupZip
                                }
                                */
                              cache.writeData({ data })
                              return null // must return null
                                          // returning anything other than null seems to trigger
                                          // error message: Missing field [mutationName] in {}
    } // setSignupPracticeInfo
  } // Mutation:
} // const resolvers

const stateLink = withClientState({
  cache,
  defaults,
  resolvers
})

const client = new ApolloClient({
  // link: authLink.concat(httpLink),
  link: ApolloLink.from([stateLink, authLink.concat(httpLink)]),
  cache: cache,
  resolvers: resolvers
})

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App client={client} />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//// serviceWorker.unregister();

export { client }
